import { render, staticRenderFns } from "./IconCautionGray.vue?vue&type=template&id=5870634e&scoped=true&"
import script from "./IconCautionGray.vue?vue&type=script&lang=js&"
export * from "./IconCautionGray.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5870634e",
  null
  
)

export default component.exports