var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homework-add-textbook-question"},[_c('div',{staticClass:"mt-3 mb-1 mx-4"},[_c('TitleHomework',{ref:"titleArea",attrs:{"help-link":_vm.$t('url.helps.addQuestion')}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('p',{staticClass:"mb-0 fw-bold"},[_vm._v(" "+_vm._s(_vm.$t("labels.addTextbookQuestion"))+" ")])])],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-auto"},[_c('FormSelectBox',{attrs:{"id":"selectedBookId","label-text":_vm.$t('labels.textbook'),"options":_vm.bookSelect,"initial-value":"0"},on:{"input":_vm.onChangeBook}})],1)]),(_vm.showErrorMessageFlg)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("messages.error.canNotPublishSelectedTextbook"))+" ")]):_vm._e(),(_vm.showCanNotGetTextbook)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("messages.error.canNotGetTextbook"))+" ")]):_vm._e(),(_vm.showCanNotGetStudent)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("messages.error.canNotGetStudent"))+" ")]):_vm._e(),(_vm.stateGroupAccounts)?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("messages.error.canNotGetSelectTextbook"))+" ")]):_vm._e(),_c('div',{staticClass:"row mb-3 question-setting"},[_c('div',{staticClass:"col-md-4 align-self-stretch tree-wrap"},[_c('TreeQuestion',_vm._g({ref:"treeQuestion",staticClass:"tree",attrs:{"list":_vm.questionList,"grayout-hidden-mode":true}},{
            'clicked-question-title': _vm.onClickedQuestionTitle,
            'change-preview-tabs': _vm.changePreviewAreaTabs,
            'on-dirty': _vm.onDirty,
          }))],1),_c('PreviewContainer',{attrs:{"image":_vm.image,"setting-list":_vm.settingList,"message-display-flg":_vm.messageDisplayFlg}})],1),_c('div',{staticClass:"row mb-1"},[_c('div',{staticClass:"col"},[_c('label',{staticClass:"text-end w-100",staticStyle:{"font-size":"85%"}},[_vm._v(" "+_vm._s(_vm.$t("labels.textbookQuestionMessage"))+" ")])])]),_c('div',{staticClass:"row mb-3 stream-data-list"},[_c('div',{staticClass:"col"},[_c('TableHomeworkStreamDataList',_vm._g({attrs:{"stream-data-list":_vm.currentHomeworkDetailItems,"display-count":5,"is-published":_vm.isPublished,"is-show-button-up-down":false,"student-items":_vm.studentItems,"book-items":_vm.bookItems,"limit":_vm.homeworkMaximumLimitItems[0].items[0].value,"stream-group":String(_vm.headerInfo.streamGroup),"stream-group-member":_vm.selectedMemberItems.groupMemberItems}},{
            delete: _vm.deleteTableRow,
            'changed-stream-data-list': _vm.changedStreamDataList,
          }))],1)]),_c('div',{staticClass:"row gx-5 justify-content-center"},[_c('div',{staticClass:"col-auto"},[_c('ButtonBorderCircleMedium',{attrs:{"label-name":_vm.$t('buttons.cancel'),"label-color":_vm.colorLayoutTheme,"border-color":_vm.colorLayoutTheme},nativeOn:{"click":function($event){return _vm.onClickCancel.apply(null, arguments)}}})],1),_c('div',{staticClass:"col-auto"},[_c('ButtonBorderCircleMedium',{attrs:{"label-name":_vm.$t('buttons.register'),"color":"layout-theme-light","label-color":_vm.colorLayoutTheme,"border-color":_vm.colorLayoutTheme},nativeOn:{"click":function($event){return _vm.onClickRegister.apply(null, arguments)}}})],1)])]),(_vm.isLoading)?_c('LoadingScreen'):_vm._e(),(_vm.isOverLimit)?_c('ModalConfirmOkOnly',{attrs:{"message":_vm.$t('messages.error.overLimitTextBookHomeWorkCount', {
        limit: _vm.homeworkMaximumLimitItems[0].items[0].value,
      })},on:{"close-confirm-modal-ok-only":_vm.onOverLimit}}):_vm._e(),(_vm.sessionDisconnect)?_c('ModalConfirmOkOnly',{attrs:{"message":_vm.$t('messages.error.overLimitSessionToken')},on:{"close-confirm-modal-ok-only":function($event){return _vm.onSessionDisconnect()}}}):_vm._e(),(_vm.isRegisterBookChange)?_c('ModalConfirmOkOnly',{attrs:{"message":_vm.$t('messages.error.registerBookChange')},on:{"close-confirm-modal-ok-only":function($event){return _vm.onRegisterBookChange()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }