import { render, staticRenderFns } from "./TableHomeworkUploadDataList.vue?vue&type=template&id=3a1149e4&scoped=true&"
import script from "./TableHomeworkUploadDataList.vue?vue&type=script&lang=js&"
export * from "./TableHomeworkUploadDataList.vue?vue&type=script&lang=js&"
import style0 from "./TableHomeworkUploadDataList.vue?vue&type=style&index=0&id=3a1149e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a1149e4",
  null
  
)

export default component.exports