<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-light mx-3">
      <div class="container-fluid">
        <div class="row justify-content-between align-items-end">
          <div class="col-md-6">
            <div class="row">
              <div class="col">
                <SViewerLogo />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span
                  class="navbar-brand fw-bold header-title"
                  @click="pushToRoot"
                >{{ $t("labels.managementSite") }}</span><span class="navbar-text">{{ loginUserInfo.schoolName }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-auto text-end">
            <div>{{ loginUserInfo.userName }} {{ $t("labels.dear") }}</div>
            <ButtonMini
              :label-name="$t('buttons.logout')"
              color="layout-theme"
              label-color="white"
              :border-color="'#ff7f27'"
              class="mini-button"
              @click.native="onLogout"
            />
          </div>
        </div>
      </div>
    </nav>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirm
      v-if="displayGroupReloadConfirm"
      :message="$t('messages.confirm.refleshGroupList')"
      v-on="{
        'ok-confirm-modal': onRefleshGroupList,
        'close-confirm-modal': () => { displayGroupReloadConfirm = false },
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import SViewerLogo from "@/components/atoms/SViewerLogo.vue"
import ButtonMini from "@/components/atoms/ButtonMini.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import ModalConfirm from '@/components/organisms/modal/ModalConfirm.vue'
import accountRepository from "@/repositories/account"
import { sukenServiceId, apiResponseCode } from "@/constant/network"

export default {
  name: "Header",
  components: {
    SViewerLogo,
    ButtonMini,
    LoadingScreen,
    ModalConfirm,
  },

  data: () => ({
    isLoading: false,
    displayGroupReloadConfirm: false,
  }),

  computed: {
    ...mapGetters(["loginUserInfo"]),
    ...mapGetters("nameConversions", ["curriculumConversionTable"]),
  },

  methods: {
    ...mapMutations([
      "clearLoginUserInfo",
      "setSessionDisconnect",
      "setGroupIdsOfLoginUser",
      "setHandleCurriculumsOfLoginUser",
    ]),
    ...mapMutations("menu", ["setActiveName"]),
    ...mapActions("menu", ["updateMenuItem"]),

    /**
     * ルート（/）にリンクを飛ばす
     * ※router-linkでやるとサイドメニューが動作しない
     */
    pushToRoot() {
      this.$router
        .push("/")
        .then((result) => {
          this.setActiveName({ activeName: result.name })
          this.updateMenuItem(result.name)
        })
        .catch(() => {
          // 現在いる場所がルートの場合、エラーが起こる
          // そもそも特に何もする必要が無いので握り潰す
          // ※リダイレクト先が取れるなら「リダイレクト先と現在の場所が同じなら何もしない」と言う判定も可能だが、
          //   恐らく取れないと思われる
        })
    },

    /**
     * グループリスト再取得
     */
    async onRefleshGroupList() {
      this.isLoading = true
      try {
        let groupIdPromise = null
        let sukenAccountInfoPromise = null
        try {
          groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
            sukenServiceId.lms,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
          sukenAccountInfoPromise = await accountRepository.getSukenAccountInfo(
            sukenServiceId.lms,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
          if (sukenAccountInfoPromise.data.status === apiResponseCode.forbidden) {
            this.setSessionDisconnect(true)
            return
          }
        } catch (error) {
          if (error.status === apiResponseCode.forbidden) {
            this.setSessionDisconnect(true)
            return
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }

        // グループID
        this.setGroupIdsOfLoginUser(groupIdPromise.data.groupList)

        // 担当教科
        const curriculums = sukenAccountInfoPromise.data.chargedSubject
        // 教科IDを変換
        let isError = false
        const handleCurriculums = curriculums.map((code) => {
          let item = this.curriculumConversionTable.find(
            (item) => code == item.code
          )

          if (!item) {
            console.error(`Invalid curriculum code: ${code}`)
            isError = true
          }
          return Number(item.value)
        })

        if (isError) {
          // 変換できなかったものがあった場合は403エラーとする
          this.$router.push({
            name: "APIError",
            params: { status: apiResponseCode.forbidden },
          })
          return
        }
        this.setHandleCurriculumsOfLoginUser(handleCurriculums)
      } finally {
        this.isLoading = false
      }
    },

    /**
     * ログアウト
     */
    onLogout() {
      this.$router.push({ name: "Login" })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  border-bottom: 6px solid var(--bs-layout-theme);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 45;

  .container-fluid {
    display: block;

    .mini-button {
      display: inline;
      margin-left: 20px;
    }
  }

  ::v-deep #sViewerLogo {
    // ロゴエリアのみで基準フォントを定義
    font-size: 100px;
    width: 4.7rem;
  }

  .header-title {
    color: var(--bs-layout-theme);
    cursor: pointer;

    &:hover,
    &:visited {
      color: var(--bs-layout-theme);
    }
  }
}
</style>
