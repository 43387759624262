import { mapMutations, mapGetters, mapActions } from 'vuex'
import sessionRepository from '@/repositories/session'
import { funcs } from '@/dbs/indexedDb'
import { apiResponseCode } from '../constant/network'

export default {
  data : function(){
    return {
      db :null,
    }
  },

  computed: {
    ...mapGetters([
      'loginUserInfo',
      'sessionDisconnect',
      'systemInfo',
    ]),

    ...mapGetters('nameConversions', {
      // 宿題管理
      nameCurriculums: 'curriculums',
      nameSubjects: 'subjects',
      nameHomeworkTypeItems: 'homeworkTypeItems',
      nameStreamStatusItems: 'streamStatusItems',
      nameHomeworkStatusItems: 'homeworkStatusItems',
      nameGradeStampItems: 'gradeStampItems',

      // 学習の記録
      nameResultClassItems: 'resultClassItems',
      namePromptClassItems: 'promptClassItems',
      nameChildQuestionStampItems: 'childQuestionStampItems',
    }),

    ...mapGetters('parameterSettings', {
      // 共通
      paramLinkTheSystemItems: 'linkTheSystemItems',
      paramUploadMaximumLimitItems: 'uploadMaximumLimitItems',
      paramReleaseEndPeriodItems: 'releaseEndPeriodItems',
      paramKeepPeriodItems: 'keepPeriodItems',
      paramDeadlinePeriodItems: 'deadlinePeriodItems',
      paramApiSettingItems: 'apiSettingItems',

      // 宿題管理
      paramCreateHomeworkMaximumLimitItems: 'createHomeworkMaximumLimitItems',
      paramHomeworkMaximumLimitItems: 'homeworkMaximumLimitItems',
    }),
  },

  methods: {
    ...mapActions([
      'clearSessionInfo',
    ]),
    ...mapMutations([
      'setSessionDisconnect',
    ]),
    /**
     * セッションをチェックする
     */
    async checkSession() {
      await funcs.responseCache.clear("systemDateTime")
      
      // セッション期間が有効かチェックする
      const validSessionPromise = await sessionRepository.validSessionTimeSpan(
        this.loginUserInfo.accountId,
        this.loginUserInfo.lmsApiToken
      ).catch(error => {
        this.$router.push({ name: "APIError", params: { status: error.status } })
        return false
      })

      // 設定値のセッション期間と算出したセッション期間を比較して、設定値を超えていればセッション切れとする
      if (!validSessionPromise) {
        await this.setSessionDisconnect(true)
        return false
      }
      return true
    },

    /**
     * APIレスポンス情報からセッション切れをチェック
     * @param {*} response 
     * @returns 
     */
    async checkSessionFromResponse(response) {
      // HTTPレスポンスコードによる判定
      if (!response || !response.status) {
        return true
      }
      if (response.status == apiResponseCode.forbidden || response.status == apiResponseCode.unauthorized) {
        await this.setSessionDisconnect(true)
        return false
      }
      // APIレスポンスデータのステータス判定
      if (!response.data) {
        return true
      }
      if (response.data.status == apiResponseCode.forbidden || response.data.status == apiResponseCode.unauthorized) {
        await this.setSessionDisconnect(true)
        return false
      }
      if (response.data.code == apiResponseCode.forbidden || response.data.code == apiResponseCode.unauthorized) {
        await this.setSessionDisconnect(true)
        return false
      }
      return true
    },

    /**
     * セッション確認後処理
     */
    async setSessionDisconnectFalse() {
      await this.setSessionDisconnect(false)
    },

    /**
     * ランダムな文字列（[a-z][0-9]）を生成
     * @param {*} len 桁数
     * @returns 指定桁のランダム文字列
     */
    getRandomAlphabetString(len){
      // 使用する文字列
      const STR = "abcdefghijklmnopqrstuvwxyz0123456789"
      return Array.from(
        crypto.getRandomValues(new Uint8Array(len)) // 指定数のランダムな8bit数値配列を返す
      ).map((n) => STR[n % STR.length]).join('')    // ランダムな数値から使用する文字列の1文字を抽出
    }
  },
}