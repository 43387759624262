import { render, staticRenderFns } from "./IconDelete.vue?vue&type=template&id=880a2e54&scoped=true&"
import script from "./IconDelete.vue?vue&type=script&lang=js&"
export * from "./IconDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "880a2e54",
  null
  
)

export default component.exports