var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"topElement",staticClass:"write-info",style:({
    top: _vm.isTeacherMode ? 0 : '-80px',
  })},[_c('div',{staticClass:"write-info_pen-canvas",style:({
      height: _vm.isTeacherMode ? '100%' : _vm.penCanvasHeight+'px',
    })},[_vm._l((_vm.pens),function(penInf,pneIdx){return [_c('div',{key:pneIdx,staticClass:"write-info_pen-instance"},[_c('svg',{staticClass:"write-info_pensvg",style:({'z-index': penInf.zIndex}),attrs:{"vesion":"1.1","xmlns":"http://www.w3.org/2000/svg","viewBox":("0 0 " + _vm.penViewBoxWidth + " " + _vm.penViewBoxHeight)}},[(penInf.type==='path')?_c('path',{class:{
              'write-info_pen-detail': !_vm.isDrawModeErase,
              'write-info_pen-detail-erase': _vm.isDrawModeErase,
            },staticStyle:{"fill":"rgba(255,255,255,0)","stroke-linecap":"round","stroke-linejoin":"round"},style:({
              stroke: _vm.penColors[penInf.color],
              'stroke-width': _vm.penSizes[penInf.size],
              opacity: _vm.penOpacities[penInf.opacity],
            }),attrs:{"d":penInf.path}}):_vm._e(),(penInf.type==='line')?_c('line',{class:{
              'write-info_pen-detail': !_vm.isDrawModeErase,
              'write-info_pen-detail-erase': _vm.isDrawModeErase,
            },style:({
              stroke: _vm.penColors[penInf.color],
              'stroke-width': _vm.penSizes[penInf.size],
              opacity: _vm.penOpacities[penInf.opacity],
            }),attrs:{"x1":penInf.x1,"y1":penInf.y1,"x2":penInf.x2,"y2":penInf.y2}}):_vm._e(),(penInf.type==='vartHori')?_c('line',{class:{
              'write-info_pen-detail': !_vm.isDrawModeErase,
              'write-info_pen-detail-erase': _vm.isDrawModeErase,
            },style:({
              stroke: _vm.penColors[penInf.color],
              'stroke-width': _vm.penSizes[penInf.size],
              opacity: _vm.penOpacities[penInf.opacity]
            }),attrs:{"x1":penInf.x1,"y1":penInf.y1,"x2":penInf.x2,"y2":penInf.y2}}):_vm._e(),(penInf.type==='rect')?_c('rect',{class:{
              'write-info_pen-detail': !_vm.isDrawModeErase,
              'write-info_pen-detail-erase': _vm.isDrawModeErase,
            },staticStyle:{"fill":"rgba(255,255,255,0)"},style:({
              stroke: _vm.penColors[penInf.color],
              'stroke-width': _vm.penSizes[penInf.size],
              opacity: _vm.penOpacities[penInf.opacity],
            }),attrs:{"x":penInf.x,"y":penInf.y,"width":penInf.width,"height":penInf.height}}):_vm._e(),(penInf.type==='ellipse')?_c('ellipse',{class:{
              'write-info_pen-detail': !_vm.isDrawModeErase,
              'write-info_pen-detail-erase': _vm.isDrawModeErase,
            },staticStyle:{"fill":"rgba(255,255,255,0)"},style:({
              stroke: _vm.penColors[penInf.color],
              'stroke-width': _vm.penSizes[penInf.size],
              opacity: _vm.penOpacities[penInf.opacity],
            }),attrs:{"cx":penInf.cx,"cy":penInf.cy,"rx":penInf.rx,"ry":penInf.ry}}):_vm._e()])])]})],2),(!(_vm.isDrawable && _vm.isDrawModeText && _vm.editable))?_c('div',{staticClass:"write-info_text-canvas"},[_vm._l((_vm.texts),function(textInf,textIdx){return [_c('div',{key:textIdx,ref:"textInstance",refInFor:true,staticClass:"write-info_text-instance",style:({top: textInf.y + 'px', left: textInf.x + 'px', 'z-index': textInf.zIndex})},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(textInf.text),expression:"textInf.text"}],ref:"textarea",refInFor:true,staticClass:"write-info_text-input-area",class:{'write-info_font_serif': textInf.textFont === 1},staticStyle:{"user-select":"none"},style:({
            'pointer-events': _vm.isDrawModeNone || (_vm.isTeacherMode && (_vm.isDrawModeText || _vm.isDrawModeErase)) ? 'auto' : 'none',
            height: (parseFloat(textInf.height)+_vm.textareaCalibrationHeight)+'px',
            width: (parseFloat(textInf.width)+_vm.textareaCalibrationWidth)+'px',
            color: _vm.textColors[textInf.textColor],
            'font-size': (_vm.textFontSizes[textInf.textSize]/_vm.pageRate)+'px',
            'background-color': ("rgba(" + (_vm.textBackColors[textInf.textBackColor]) + ")"),
            'border-color': _vm.isTeacherMode && _vm.isDrawModeErase ? 'revert' : 'rgba(0,0,0,0)', 
          }),attrs:{"readonly":"","autocapitalize":"none","spellcheck":"false"},domProps:{"value":(textInf.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(textInf, "text", $event.target.value)}}})])]})],2):_vm._e(),_c('div',{staticClass:"write-info_stamp-canvas"},[_vm._l((_vm.writeInfo.stamps),function(stampInf,stampIdx){return [_c('div',{key:stampIdx,staticClass:"write-info_stamp-instance",style:({
          top: stampInf.y + 'px',
          left: stampInf.x + 'px',
          width: _vm.stampSizes[stampInf.size]/_vm.pageRate + 'px',
          height: _vm.stampSizes[stampInf.size]/_vm.pageRate + 'px',
          fill: _vm.stampColors[stampInf.color],
          'z-index': stampInf.zIndex
        }),domProps:{"innerHTML":_vm._s(_vm.getStampSvg(stampInf.stampIdx))}})]})],2),(_vm.creatingTextRect && _vm.creatingTextRect.display)?_c('div',{staticClass:"text-create-rect",style:({
      top: _vm.creatingTextRect.y+'px',
      left: _vm.creatingTextRect.x+'px',
      width: _vm.creatingTextRect.width+'px',
      height: _vm.creatingTextRect.height+'px'
    })}):_vm._e(),_c('div',{ref:"uiArea",staticClass:"write-info_ui-area",class:{peN: _vm.isDraggingText}},[_vm._l((_vm.texts),function(textInf,textIdx){return [(_vm.isDrawable && _vm.isDrawModeText && _vm.editable)?_c('div',{key:textIdx,staticClass:"write-info_text-instance",style:({ top: textInf.y + 'px', left: textInf.x + 'px', 'z-index': textInf.zIndex })},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(textInf.text),expression:"textInf.text"}],ref:"textarea",refInFor:true,staticClass:"write-info_text-input-area",class:{'write-info_font_serif': textInf.textFont === 1},style:({
            height: textInf.height + 'px',
            width: textInf.width + 'px',
            color: _vm.textColors[textInf.textColor],
            'font-size': (_vm.textFontSizes[textInf.textSize]/_vm.pageRate) + 'px',
            'background-color': ("rgba(" + (_vm.textBackColors[textInf.textBackColor]) + ")"),
          }),attrs:{"readonly":!_vm.isIPad(),"autocapitalize":"none","spellcheck":"false"},domProps:{"value":(textInf.text)},on:{"click":function($event){return _vm.onTextareaClick(textIdx)},"focus":function($event){return _vm.onTextareaFocus(textIdx)},"blur":function($event){return _vm.onTextareaBlur(textIdx)},"input":function($event){if($event.target.composing){ return; }_vm.$set(textInf, "text", $event.target.value)}}}),_vm._v(" "),_c('img',{ref:"textareaResizeHandle",refInFor:true,staticClass:"resize-handle",style:({
            top: textInf.height + 'px',
            left: textInf.width + 'px',
          }),attrs:{"src":require("@/assets/images/tools/textPanel/resize_hunde.png"),"alt":"リサイズ"}})]):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }