import { render, staticRenderFns } from "./PageNotFoundContent.vue?vue&type=template&id=516e3abf&scoped=true&"
import script from "./PageNotFoundContent.vue?vue&type=script&lang=js&"
export * from "./PageNotFoundContent.vue?vue&type=script&lang=js&"
import style0 from "./PageNotFoundContent.vue?vue&type=style&index=0&id=516e3abf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516e3abf",
  null
  
)

export default component.exports