<template>
  <div class="failed-login-content mt-5 mb-3 mx-4 login-form-fontFamily">
    <div
      class="row justify-content-center caution-border caution-background py-2 mb-4"
    >
      <div class="col-12 d-flex justify-content-center">
        <h5 class="text-flex">
          <IconCaution
            width="30px"
            height="30px"
          />
          <font
            :color="colorCautionFont"
            style="font-weight: bold"
          >
            アカウントが連携されていません。
          </font>
        </h5>
      </div>
    </div>
    <div
      class="row justify-content-center info-border info-background py-4 mb-5"
    >
      <div class="col-11 offset-1">
        <h5 style="font-weight: normal">
          数研アカウントのID・パスワードでマイページにログイン後、
        </h5>
      </div>
      <div class="col-11 offset-1 mb-2">
        <h5 style="font-weight: normal">
          アカウント情報<IconAccountInfo
            height="3.5rem"
            width="3.5rem"
          />からソーシャルアカウントの連携を行ってください。
        </h5>
      </div>
      <div class="col-11 offset-1 mb-2">
        <p class="mb-0">
          ※別のアカウントで再度ソーシャルログインする場合は、
        </p>
        <p class="mb-0">
          一度ログイン中のソーシャルアカウントをログアウトしてください。
        </p>
        <p class="mb-0">
          （ログイン中は、ログイン認証画面が表示されません。）
        </p>
        <p class="mb-0">
          ログアウトの方法は各ソーシャルアカウントのサイトをご確認ください。
        </p>
        <p class="mb-0">
          アプリ版をお使いの方でソーシャルアカウントをログアウトできない場合は、
        </p>
        <p class="mb-0">
          <a
            :href="linkUrl"
            target="_blank"
          >こちら</a>からお問い合わせください。
        </p>
      </div>
      <div class="col-11 offset-1 mb-1">
        <span><IconCautionGray
          width="1rem"
          color-style="#ffffff"
        />&nbsp;Apple
          IDをご利用のお客様へ</span>
      </div>
      <div class="col-10 apple-background px-4 py-3">
        <div class="col-12">
          <span>
            &nbsp;Apple IDでソーシャルアカウントの連携を行うには、Apple
            IDのログイン認証後に
          </span>
        </div>
        <div class="col-12">
          <span>
            &nbsp;表示されるメールアドレス設定画面にて「メールを共有」を選択してください。
          </span>
        </div>
        <div class="col-12">
          <span>
            &nbsp;（「メールを非公開」を選択すると、ソーシャルアカウントの連携をご利用いただけません。）
          </span>
        </div>
      </div>
      <div class="col-11 offset-1 mt-3 mb-1">
        <span><IconCautionGray
          width="1rem"
          color-style="#ffffff"
        />&nbsp;Microsoftアカウントをご利用のお客様へ
          </span>
      </div>
      <div class="col-10 apple-background px-4 py-3">
        <div class="col-12">
          <span>
            &nbsp;Microsoftアカウントでソーシャルアカウントの連携を行うには、
          </span>
        </div>
        <div class="col-12">
          <span>
            &nbsp;Microsoftアカウントのアカウント情報にメールアドレスを必ず設定してください。
          </span>
        </div>
        <div class="col-12">
          <span>
            &nbsp;（メールアドレスの設定がないアカウントでは、ソーシャルアカウントの連携をご利用いただけません。）
          </span>
        </div>
      </div>
    </div>

    <div id="button_area">
      <span class="btn-white">
        <router-link :to="{ name: 'Login' }">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.returnToLogin')"
            border-color="#2D7CB9"
            border-size="0.2rem"
            width="100%"
          />
        </router-link>
      </span>

      <span class="btn-white">
        <ButtonBorderCircleMedium
          :label-name="$t('buttons.jumpToSukenMypage')"
          border-color="#2D7CB9"
          border-size="0.2rem"
          width="100%"
          @click.native="onClickJumpToSukenMypage"
        />
      </span>
    </div>
  </div>
</template>

<script>
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import IconAccountInfo from "@/components/atoms/icons/IconAccountInfo.vue"
import IconCaution from "@/components/atoms/icons/IconCaution.vue"
import IconCautionGray from "@/components/atoms/icons/IconCautionGray.vue"

export default {
  name: "FailedLoginContent",
  components: {
    ButtonBorderCircleMedium,
    IconAccountInfo,
    IconCaution,
    IconCautionGray,
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27",
    colorCautionFont: "#F01C1E",
    linkUrl:
      "https://reg18.smp.ne.jp/regist/is?SMPFORM=mhme-lenhkj-ea4c77fc49d3e3a7d92b5efbde1e0855",
  }),
  methods: {
    onClickJumpToSukenMypage() {
      window.location.href = "https://account.chart.co.jp/login/user"
    },
  },
}
</script>

<style lang="scss" scoped>
.failed-login-content {
  margin: 2rem;
}
.login-form-fontFamily {
  font: 400 87.5%/1.5em "Open Sans", sans-serif;
}
.sviewer-logo-title {
  font-size: 51px;
}
.content {
  padding: 1.5em;
}
.caution-border {
  border: solid 0.2rem #f74e17;
}
.caution-background {
  background: #ffeee1;
}
.info-border {
  border: solid 0.2rem #b8b8b8;
}
.info-background {
  background: #eeeeee;
}
.apple-background {
  background: #d9ecff;
}
.text-flex {
  margin-top: 0;
  margin-bottom: 0;
}
#button_area {
  margin: 30px auto 0px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.btn-white {
  width: 320px;
  padding: 0.8em;
}

.footer {
  justify-content: center;
}
</style>
