<template>
  <div
    class="homework-progress"
  >
    <div class="container-fluid">
      <div class="row mt-3 mb-2 justify-content-around">
        <div class="col-auto">
          <TitleHomework :help-link="$t('url.helps.homeworkProgress')" />
        </div>
        <div class="col text-center homework-name py-2">
          {{ homeworkName }}
        </div>
        <div class="col-auto">
          <div class="d-flex flex-column">
            <div>
              {{
                $t("labels.startDateReplaceWords", {
                  date: "：" + params.startDate,
                })
              }}
            </div>
            <div>
              {{
                $t("labels.deadlineDateReplaceWords", {
                  date: "：" + params.deadlineDate,
                })
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col mt-2 mb-2">
          <div class="row justify-content-around align-items-center">
            <div class="col-auto">
              <router-link :to="{ name: 'Homework' }">
                <ButtonReturn
                  :label-name="$t('buttons.returnToList')"
                  label-color="#ff7f27"
                  border-color="#ff7f27"
                />
              </router-link>
            </div>
            <div class="col-auto">
              <div class="bulk-return">
                <div
                  class="bulk-return-button"
                  :class="{ hide: isBulkMode }"
                >
                  <ButtonBulkReturn
                    :label-name="labelBulkMode"
                    color="layout-theme-light"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    font-size="0.9rem"
                    @click.native="onClickBulkMode"
                  />
                </div>
                <div
                  v-if="isBulkMode"
                  class="bulk-return-menu"
                >
                  <div class="bulk-return-menu-frame">
                    <label>{{ labelSubmissionStatus }}</label>
                    <FormSelectBox
                      id="submissionStatus"
                      ref="submissionStatusSelectBox"
                      :value="selectedSubmissionStatus"
                      :options="generateSubmissionStatusItems"
                      :initial-value="initialSubmissionStatus"
                      :disabled="false"
                      :is-require="false"
                      @input="onSelectSubmissionStatus"
                    />

                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.cancel')"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      font-size="0.9rem"
                      width="100px"
                      padding="0"
                      @click.native="onClickBulkMode"
                    />

                    <ButtonBorderCircleMedium
                      :label-name="labelBulkReturn"
                      color="layout-theme-light"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      font-size="0.9rem"
                      width="100px"
                      padding="0"
                      :disabled="bulkReturnDisabled"
                      @click.native="onClickBulkReturn"
                    />
                  </div>
                </div>
              </div>              
            </div>
            <div class="col text-end">
              <div class="d-flex justify-content-end align-items-center">
                <!-- <ButtonExportProgress
                  :label-name="$t('buttons.fileExport')"
                  color="layout-theme-light"
                  :label-color="colorLayoutTheme"
                  :border-color="colorLayoutTheme"
                  @click.native="onClickExport(item)"
                />
                <ButtonBorderCircleMedium
                  :label-name="$t('buttons.homeworkEvalSet')"
                  color="layout-theme-light"
                  label-color="#ff7f27"
                  border-color="#ff7f27"
                  width="180px"
                  padding="0"
                  font-size="0.9rem"
                  vertical-align="baseline"
                  line-height="1"
                  @click.native="onClickHomeworkEvalSet"
                /> -->
                <div
                  class="sub-functions-area"
                >
                  <div
                    ref="sub-functions"
                    class="sub-functions"
                  >
                    <div
                      class="button-leader"
                      @click="toggleSubFunction"
                      v-text="'･･･'"
                    ></div>
                    <div
                      class="sub-menu-list"
                      :hidden="!showSubMenu"
                    >
                      <div
                        class="sub-menu-item"
                        @click="onClickExport"
                        v-text="$t('buttons.fileExport')"
                      ></div>
                      <div
                        class="sub-menu-item"
                        @click="onClickHomeworkEvalSet"
                        v-text="$t('buttons.homeworkEvalSet')"
                      ></div>
                    </div>
                  </div>
                </div>                
                <CheckBox
                  ref="showStudentNameCheckBox"
                  label="生徒名を表示"
                  :initial-checked="true"
                  @input="onChangedShowStudentNameCheckBox"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref="containerScroller"
        class="row container-scroller"
      >
        <div class="col">
          <div class="w-100 ps-3 pt-0">
            <div
              v-if="showWhole"
              ref="wholeArea"
              class="row mb-4"
            >
              <div class="col pe-0">
                <TableHomeworkProgressWhole
                  :header-items="!isLoading ? homeworkHeaderItems : []"
                  :child-question-rate-items="
                    !isLoading ? childQuestionRateItems : []
                  "
                  :book-items="bookItems"
                  :student-items="studentItems"
                  :homework-details="homeworkDetails"
                  v-on="{
                    'opend': tableHomeworkProgressWholeOpenedHandler,
                    'closed': tableHomeworkProgressWholeClosedHandler,
                  }"
                />
              </div>
            </div>
            <div class="row">
              <div class="col pe-0">
                <TableHomeworkProgressStudents
                  ref="tableHomeworkProgressStudents"
                  :header-items="!isLoading ? homeworkHeaderItems : []"
                  :items="!isLoading ? homeworkProgressItems : []"
                  :view-items="!isLoading ? viewProgressItems : []"
                  :grade-stamps="stamps"
                  :teacher-comment="teacherComment"
                  :haishin-start-date="haishinStartDate"
                  :show-student-name="showStudentName"
                  :progress-params="params"
                  :homework-details="homeworkDetails"
                  :ques-image-list.sync="quesImageList"
                  :config-page-teacher.sync="configPageTeacher"
                  :cond-teacher.sync="condTeacher"
                  :show-bulk-check="showBulkCheck"
                  :show-whole="showWhole"
                  v-on="{
                    'on-click-return-of': onClickReturnOf,
                    'on-click-send-back': onClickSendBack,
                    'on-click-send-save': onClickSendSave,
                    'on-checked-student-data': onCheckedStudentData,
                    'on-change-show-student-name':
                      onChangedShowStudentNameCheckBox,
                    'on-reload': reloadStudentsInfo,
                    'on-close-bulk-return': closeModalBulkReturn,
                    'on-show-whole': tableHomeworkProgressWholeOpenedHandler,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalSettingEval
      v-if="showHomeworkEvalSetModal"
      :initial-data="homeworkEvalSet"
      :is-common="false"
      v-on="{
        set: onClickEvalSet,
        cancel: closeModalHomeworkEvalSet,
      }"
    />
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
    <LoadingScreen v-if="isLoading" />
    <!-- isLoading2は内部データを読み直さない場合に使うローディング -->
    <LoadingScreen v-if="isLoading2" />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題進捗
 */
import { mapGetters, mapMutations, mapActions } from "vuex";
import mixin from "@/mixins/mixin";
import TitleHomework from "@/components/molecules/TitleHomework.vue";
import ButtonReturn from "@/components/atoms/buttons/ButtonReturn.vue";
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import FormSelectBox from "@/components/atoms/FormSelectBox.vue"
// TODO:非表示の要望がありコメントアウト。
// import ButtonImage from '@/components/atoms/buttons/ButtonImage.vue'
import CheckBox from "@/components/atoms/CheckBox.vue";
import TableHomeworkProgressWhole from "@/components/organisms/TableHomeworkProgressWhole.vue";
import TableHomeworkProgressStudents from "@/components/organisms/TableHomeworkProgressStudents.vue";
import LoadingScreen from "@/components/atoms/LoadingScreen.vue";
import homeworkRepository from "@/repositories/homework";
import accountRepository from "@/repositories/account";
import questionRepository from "@/repositories/question"
import { handedStatus, homeworkTypeCode, streamFileInfoMode, noteType } from "@/constant/homework"
import ModalSettingEval from "@/components/organisms/modal/ModalSettingEval.vue"
import { db, funcs } from "@/dbs/indexedDb"
import ButtonBulkReturn from "../components/atoms/buttons/ButtonBulkReturn.vue";
import { apiResponseCode } from "@/constant/network"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"

export default {
  name: "HomeworkProgress",
  components: {
    TitleHomework,
    ButtonReturn,
    ButtonBulkReturn,
    ButtonBorderCircleMedium,
    // TODO:非表示の要望がありコメントアウト。
    // ButtonImage,
    CheckBox,
    TableHomeworkProgressWhole,
    TableHomeworkProgressStudents,
    LoadingScreen,
    ModalSettingEval,
    FormSelectBox,
    ModalConfirmOkOnly,
},

  mixins: [mixin],
  props: {
    progressParams: {
      type: Object,
      require: true,
      default: function () {
        return {};
      },
    },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      params: this.progressParams,
      homeworkName: "",
      homeworkHeaderItems: [],
      homeworkProgressItems: [],
      viewProgressItems: [],
      childQuestionRateItems: [],
      isLoading: false,
      isLoading2: false,
      // 初期値はチェックボックス ON とする
      showStudentName: true,
      teacherComment: "",
      haishinStartDate: "",
      // 生徒別データで選択したスタンプコード
      selectedStampCode: 0,
      // 生徒名称リスト
      groupMemberItems: [],
      studentItems: [],
      bookItems: [],
      homeworkDetails: {},
      systemDate: "",
      jsons: {},
      configPageTeacher: {},
      condTeacher: {},
      quesImageList: [],
      // 宿題評価設定
      showHomeworkEvalSetModal: false,
      homeworkEvalSet: {},
      tableHomeworkProgressWholeHeightClose: NaN,
      tableHomeworkProgressWholeHeightOpen: NaN,
      // 一括返却
      isBulkMode: false,
      labelBulkMode: "一括返却",
      labelBulkReturn: "返却",
      showBulkCheck: false,
      // 提出状況
      labelSubmissionStatus: "返却対象",
      selectedSubmissionStatus: 0,
      initialSubmissionStatus: "0",

      showWhole: true,
      showSubMenu: false,
    };
  },

  watch: {
    async showWhole(v) {
      let displayPreviousValues = await funcs.displayPreviousValues.get()
      Object.assign(displayPreviousValues, {
        showHomeworkProgressWhole: v
      })
      await funcs.displayPreviousValues.put(displayPreviousValues)
    }
  },

  computed: {
    ...mapGetters("gradeStamp", ["stamps"]),
    ...mapGetters("homework", [
      "temporaryProgressParams",
      "checkedStudentList",
    ]),
    ...mapGetters("nameConversions", ["gradeStampItems"]),
    /**
     * 提出ステータスプルダウンリスト生成
     */
    generateSubmissionStatusItems: function () {
      const array = [{
        label: '選択してください', value: '0'
      }].concat(this.homeworkProgressItems.map((v) => {
        // ステータスをプルダウン情報にコンバート
        return this.$refs.tableHomeworkProgressStudents.convertHandedStatusToSelectionValue(
          v.status,
          v.expired
        )
      }).reduce((a, v) => {
        // 重複を除去
        if (!a.some(e => e.value === v.value)) {
          a.push(v)
        }
        return a
      }, []))
      
      // ソート
      array.sort((a, b) => {
        return Number(a.value) > Number(b.value) ? 1 : -1
      })
      
      return array
    },    
    bulkReturnDisabled: function () {
      return this.$refs.tableHomeworkProgressStudents.getBulkReturnDisabled()
    },
  },
  created: function () {
    if (!Object.keys(this.params).length) {
      this.params = JSON.parse(JSON.stringify(this.temporaryProgressParams));
    } else {
      // 宿題一覧から貰ったパラメータが存在すればそれをstoreに保存する
      // ※this.progressParamsはリロードしたら消えてしまうため
      this.setTemporaryProgressParams(JSON.parse(JSON.stringify(this.params)));
    }
    this.homeworkName = this.params.homeworkName;
  },
  mounted: async function () {
    console.time('mounted');
    // セッション期間が有効かチェックする
    if (!await this.checkSession()) {
      return
    }

    window.document.addEventListener('click', this.closeSubMenu)

    let displayPreviousValues = await funcs.displayPreviousValues.get()
    let showHomeworkProgressWhole =
      displayPreviousValues && displayPreviousValues.showHomeworkProgressWhole !== undefined
      ? displayPreviousValues.showHomeworkProgressWhole
      : undefined

    if (showHomeworkProgressWhole !== undefined) {
      this.showWhole = showHomeworkProgressWhole;
    }

    // 教材パッケージ一覧のレスポンスキャッシュをクリア
    await funcs.responseCache.clearMatch("/accounts/products/books");
    
    // 宿題データのロード
    await this.loadHwData(true);
    this.isLoading = true;

    if (this.homeworkProgressItems.length > 0) {
        this.deleteTransferStudentList(this.params.homeworkKey)

      this.homeworkProgressItems.forEach((item) => {
        switch (item.status) {
          case handedStatus.handed:
            // グループから外されている場合
            if (item.accountName == "―") {
              // 転校した生徒のIDをStoreに保存
              this.addTransferStudentList({
                homeworkKey: this.params.homeworkKey,
                studentId: item.accountId,
              });
            }
            break;
          case handedStatus.handChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returned:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returnChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;

          default:
            break;
        }
      });
    }

    // 問題画像の準備
    await this.setupQuesImageList();
    this.isLoading = false;
    console.timeEnd('mounted');
  },

  beforeDestroy() {
    window.document.removeEventListener('click', this.closeSubMenu)
  },

  methods: {
    ...mapActions("gradeStamp", ["saveGradeStamps"]),
    ...mapActions("homework", ["addCheckedStudentProgress", "addTransferStudentList"]),
    ...mapMutations("homework", ["setTemporaryProgressParams", "deleteTransferStudentList"]),

    /**
     * セッションが切れた際のログアウト処理
     */
     async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },
    /**
     * 配信先の選択済リストを初期化
     */
     async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
    },

    async loadHwData(isLoadAccountInfo) {
      this.isLoading = true;

      // 宿題進捗情報を取得
      let promise = null;
      try {
        promise = await homeworkRepository.getHomeworkProgressList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.params.homeworkKey,
          this.params.deadlineDate,
          this.loginUserInfo.lmsApiToken,
          this.paramApiSettingItems.find(v => v.itemName === 'MSHW0403').items
        );
      } catch (error) {
        console.error(error);
        this.$router.push({ name: "APIError", params: { status: error.status } });
      }

      // システム日付
      this.systemDate = promise.systemDate;

      // ヘッダー情報
      // 親問題単位で扱えるように加工する
      const headers = promise.headerItems;
      const childQuestionRates = promise.childQuestionRateItems;
      const headerItems = [];
      const childQuestionRateItems = [];
      headers.forEach((header, index) => {
        const headerIndex = Number(header.split("\n")[0]) - 1;
        if (!headerItems[headerIndex]) {
          headerItems[headerIndex] = [];
          childQuestionRateItems[headerIndex] = [];
        }
        headerItems[headerIndex].push(header);
        childQuestionRateItems[headerIndex].push(childQuestionRates[index]);
      });
      this.homeworkHeaderItems = headerItems;

      // 子問題別レート情報
      this.childQuestionRateItems = childQuestionRateItems;

      // 進捗詳細情報
      this.homeworkProgressItems = promise.progressItems;

      // 先生コメント
      this.teacherComment = promise.teacherComment;

      // 宿題評価設定
      this.homeworkEvalSet = promise.homeworkEvalSet;

      // 配信開始日
      this.haishinStartDate = this.params.startDate;

      // 採点スタンプ画像情報が無い場合は、S3 より取得する
      if (!this.stamps.length) {
        // 採点スタンプ画像を取得
        homeworkRepository
          .getGradeStamps(this.gradeStampItems)
          .then((response) => {
            // store に登録
            this.saveGradeStamps(response);
          });
      }

      if (isLoadAccountInfo) {
        try {
          await this.getAccountWithBookItems();
        } catch (error) {
          if (error && (error.status === apiResponseCode.forbidden || error.status === apiResponseCode.unauthorized)) {
            this.isLoading = false;
            this.setSessionDisconnect(true)
            return
          }
          console.log(error);
          this.isLoading = false;
        }
      }

      try {
        await this.getStudentsInfo(this.params.homeworkKey, this.params.schoolId);
      } catch (error) {
        console.log(error);
      } finally {
        homeworkRepository.setStudentsInfo(
          this.homeworkHeaderItems,
          this.homeworkProgressItems,
          this.groupMemberItems,
          this.childQuestionRateItems,
          this.homeworkDetails
        );
        this.updateHandedStatus();
        this.isLoading = false;
      }

      // 宿題情報をノート情報に反映
      for (const studentData of this.homeworkProgressItems) {
        for (const note of studentData.noteItems) {
          if (note.noteType !== noteType.writeInfo) {
            continue;
          }
          note.homework_syubetu_kbn = this.homeworkDetails.homeworkInfo.find(hw => hw.homeworkEdaNo === note.homeworkEdaNo).kind;
        }
      }
    },
    // 返却押下時保存処理
    async onClickReturnOf(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            1,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );
          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    // やり直し押下時
    async onClickSendBack(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            2,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );

          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }        
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    // 登録押下時
    async onClickSendSave(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            3,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );

          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    async reloadStudentsInfo() {
      await this.loadHwData(false);
    },
    checkExlusive(error) {
      return error.status === 409 || error.status === 550;
    },
    async getAccountWithBookItems() {
      try {
        const responce = await accountRepository.getSukenAccountWithBooks(
          this.params.groupId,
          this.loginUserInfo.accountId,
          this.params.curriculum,
          this.loginUserInfo.sessionToken,
          this.paramApiSettingItems.find(v => v.itemName === 'accountsProductsBooks').items
        );
        this.studentItems = responce.accountItems;
        this.bookItems = responce.bookItems;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * 生徒情報を取得する処理
     */
    async getStudentsInfo(homeworkKey, schoolId) {
      const promise = await homeworkRepository.getStudentsInfo(
        this.loginUserInfo.accountId,
        schoolId,
        homeworkKey,
        this.loginUserInfo.lmsApiToken,
        this.loginUserInfo.sessionToken,
        this.nameCurriculums,
        this.paramApiSettingItems,
      );
      this.groupMemberItems = promise.groupMemberItems;
      this.homeworkDetails = promise.homeworkDetails;
    },
    /**
     * 提出ステータスを更新
     */
    updateHandedStatus() {
      const checkedIdList =
        this.checkedStudentList[this.params.homeworkKey] || [];
      this.homeworkProgressItems.forEach((item) => {
        // 「提出済み」かつStoreに確認済み情報が保持されていればステータスを「確認済み」とする
        if (
          item.status === handedStatus.handed &&
          checkedIdList.some((x) => x === item.accountId)
        ) {
          this.$set(item, "status", handedStatus.handChecked);
        }
      });

      // 表示用進捗詳細情報リストを取得
      this.viewProgressItems = homeworkRepository.getViewProgressItems(
        this.homeworkProgressItems,
        this.nameHomeworkStatusItems,
        this.homeworkHeaderItems,
        this.progressParams.homeworkKey,
        this.$t("labels.expired")
      );
    },
    /**
     * 生徒のデータを確認した
     */
    onCheckedStudentData(accountId) {
      const homeworkProgressItem = this.homeworkProgressItems.find((item) => item.accountId == accountId);
      if (
        homeworkProgressItem.status == handedStatus.notProgress ||
        homeworkProgressItem.status == handedStatus.sendBack || 
        homeworkProgressItem.status == handedStatus.sendBackCheck
      ) {
        // 未提出・やり直しの場合は確認済にしない
        return;
      }
      // 確認した生徒のIDをStoreに保存
      this.addCheckedStudentProgress({
        homeworkKey: this.params.homeworkKey,
        studentId: homeworkProgressItem.accountId,
      });
      // ステータス更新
      this.updateHandedStatus();
    },
    /*
     * 生徒名の表示フラグ変更
     */
    onChangedShowStudentNameCheckBox(isShow) {
      this.showStudentName = isShow;
      this.$refs.showStudentNameCheckBox.setChecked(isShow);
    },
    /**
     * 問題画像を取得する
     */
    async setupQuesImageList() {
      this.configPageTeacher = await homeworkRepository.getBookConfig('config_page_teacher.json');
      this.condTeacher = await homeworkRepository.getBookConfig('cond_teacher.json');
      const imgList = [];
      const condOriginalUrl = "cond_original.json";
      const configPageOriginalUrl = "config_page_original.json";
      const curriculum = this.homeworkDetails.curriculum;
      for(let lessonIdx = 0; lessonIdx < this.homeworkDetails.homeworkInfo.length; lessonIdx++){
        const detail = this.homeworkDetails.homeworkInfo[lessonIdx];
        const lessonNo = lessonIdx + 1;
        switch(detail.kind){
          case homeworkTypeCode.textbook:     // 書籍
            try{
              await this.getBookAndStudentAccount(detail.bookId);
              const img = JSON.parse(JSON.stringify(detail));
              const basePath = `textbook/${curriculum}/${detail.bookId}`;
              img.filePath = await this.getQuestionImage(`${basePath}/${detail.chapterId}/page/learning_img/${detail.quesParentId}.jpg`);
              img.lessonNo = lessonNo;
              img.cond = await this.getJson(`${basePath}/000/json/cond.json`);
              img.configPage = await this.getJson(`${basePath}/000/json/config_page.json`);
              imgList.push(img);
              console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${detail.quesParentId}, taskName:${img.taskName}`);
            }catch(err){
              // API呼び出し時のエラーログは別途出力済み
            }
            break;
          case homeworkTypeCode.pdf:          // PDF
          case homeworkTypeCode.stdbLayout:   // STDB(レイアウト通り)
            {
              // 1課題で複数ページがある
              try{
                const files = await this.getFiles(detail);
                for(let i = 0; i < files.length; i++){
                  const file = files[i];
                  const img = JSON.parse(JSON.stringify(detail));
                  img.lessonNo = lessonNo;
                  img.pageNo = i + 1;
                  img.maxPage = files.length;
                  img.filePath = file.filePath;
                  img.cond = await homeworkRepository.getBookConfig(condOriginalUrl);
                  img.configPage = await homeworkRepository.getBookConfig(configPageOriginalUrl);
                  imgList.push(img);
                  console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${file.fileName}, taskName:${img.taskName}, pageNo:${img.pageNo}, maxPage:${img.maxPage}`);
                };
              }catch(err){
                console.error(`問題画像取得エラー`);
              }
            }
            break;
          case homeworkTypeCode.stdb:         // STDB(1枚づつ)
            {
              try{
                const files = await this.getFiles(detail);
                let pageNo = 0;
                for (let i = 0; i < files.length; i++) {
                  const file = files[i];
                  if (file.fileName && file.fileName.match(/^Q/)) {
                    // ファイル名が"Q"で始まるものが問題用画像
                    const img = JSON.parse(JSON.stringify(detail));
                    img.lessonNo = lessonNo;
                    img.pageNo = ++pageNo;
                    img.filePath = file.filePath;
                    img.cond = await homeworkRepository.getBookConfig(condOriginalUrl);
                    img.configPage = await homeworkRepository.getBookConfig(configPageOriginalUrl);
                    imgList.push(img);
                    console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${file.fileName}, taskName:${img.taskName}, pageNo:${img.pageNo}, maxPage:${img.maxPage}`);
                    break;
                  }
                };
              }catch(err){
                console.error(`問題画像取得エラー`);
              }
            }
            break;
        }
      };
      for(const img of imgList){
        if(img.filePath != ""){
          await this.loadImage(img);
        }
      }
      this.quesImageList.splice(0, this.quesImageList.length, ...imgList)
    },
    async getBookAndStudentAccount(bookId) {
      const bookAndAccount = await accountRepository.getBookAndStudentAccount(
        this.studentItems,
        this.bookItems,
        bookId
      )

      // 商品からの教材削除等で、bookIdに対応する書籍や生徒アカウント情報が取得できなかった場合
      if (!bookAndAccount || !bookAndAccount.student || !bookAndAccount.book) {
        throw new Error('bookIdに対応する書籍や生徒アカウント情報が存在しない')
      }

      await this.getCredentials(bookAndAccount)
    },
     async getCredentials(bookAndAccount) {
      try {
        return await accountRepository.getCookieSukenAccountWithBooks(
          this.homeworkDetails.groupId,
          bookAndAccount.student.accountId,
          bookAndAccount.book.productIds[0],
          bookAndAccount.book.bookId,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async getJson(url){
      if(!(url in this.jsons)){
        this.jsons[url] = await questionRepository.getSviewerJson(url);
      }
      return this.jsons[url];
    },
    async getQuestionImage(url){
      const img = await questionRepository.getQuestionImage(url);
      return img;
    },
    async getFiles(detail){
      switch(detail.kind){
        case homeworkTypeCode.pdf:          // PDF
        case homeworkTypeCode.stdbLayout:   // STDB(レイアウト通り)
          return await homeworkRepository.getHomeworkStreamFileListForPreview(
            this.loginUserInfo.accountId,
            this.loginUserInfo.schoolId,
            streamFileInfoMode.questionList,
            detail.homeworkKey,
            detail.homeworkEdaNo,
            this.loginUserInfo.lmsApiToken
          );
        case homeworkTypeCode.stdb:         // STDB(1枚づつ)
          return await homeworkRepository.getHomeworkStreamFileListForStdbPreview(
            this.loginUserInfo.accountId,
            this.loginUserInfo.schoolId,
            streamFileInfoMode.questionList,
            detail.homeworkKey,
            detail.homeworkEdaNo,
            detail.fileNmSeq,
            this.loginUserInfo.lmsApiToken
          );
        default:
          return null;
      }
    },
    loadImage(imgInfo){
      return new Promise((resolve, reject)=>{
        const img = new Image();
        img.onload = ()=>{
          imgInfo.width = img.naturalWidth;
          imgInfo.height = img.naturalHeight;
          resolve();
        };
        img.onerror = e=>reject(e);
        img.src = imgInfo.filePath;
      });
    },
    /**
     * Excelファイルエクスポート
     */
    async onClickExport() {
      this.showSubMenu = false;
      this.isLoading = true;

      homeworkRepository.outputProgressInfo(
        this.params,
        this.homeworkHeaderItems,
        this.childQuestionRateItems,
        this.viewProgressItems,
      );

      this.isLoading = false;
    },
    /**
     * 宿題評価設定
     */
     async onClickHomeworkEvalSet() {
      console.log(this.homeworkEvalSet)
      this.showSubMenu = false
      this.showHomeworkEvalSetModal = true
    },
    closeModalHomeworkEvalSet() {
      this.showHomeworkEvalSetModal = false
    },
    // 設定押下時
    async onClickEvalSet(evalSet) {
      this.isLoading = true
      try {
        await homeworkRepository.updateHomeworkEvalSet(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.accountId,
          this.params.homeworkKey,
          evalSet,
        );
        await this.loadHwData(false)
      } catch (error) {
        console.log(error)
        this.isLoading = false
        if (!(await this.checkSessionFromResponse(error))) {
          return
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        });
      } finally {
        this.showHomeworkEvalSetModal = false
      }
    },

    /** 全体の表のオープンハンドラ */
    async tableHomeworkProgressWholeOpenedHandler() {
      this.showWhole = true;
      // テーブルを下のほうにスクロールしている場合でも全体表が見えるようにスクロール位置を変更
      this.$refs.containerScroller.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    /** 全体の表のクローズハンドラ */
    async tableHomeworkProgressWholeClosedHandler() {
      this.showWhole = false;
    },
    /**
     * 提出状況セレクトボックス選択
     */
    onSelectSubmissionStatus: async function (event) {
      this.selectedSubmissionStatus = event
      if (event != 0) {
        this.showBulkCheck = true
        await this.$nextTick()
        this.$refs.tableHomeworkProgressStudents.selectBulkReturnStatus(this.selectedSubmissionStatus)
      } else {
        this.showBulkCheck = false
      }
    },
    /**
     * 宿題の一括返却切替
     */
    onClickBulkMode: function () {
      this.showBulkCheck = false
      this.isBulkMode = !this.isBulkMode
    },
    /**
     * 宿題の一括返却
     */
    onClickBulkReturn: function () {
      if (this.bulkReturnDisabled) {
        return
      }
      this.$refs.tableHomeworkProgressStudents.onClickBulkReturn()
    },
    closeModalBulkReturn() {
      this.showBulkCheck = false
      this.isBulkMode = false
    },
    /**
     * サブメニュー表示切り替え
     */
    toggleSubFunction($event) {
      $event.stopPropagation()
      this.showSubMenu = !this.showSubMenu
    },
    closeSubMenu() {
      this.showSubMenu = false
    },
  },
};
</script>

<style lang="scss" scoped>
.homework-progress {
  overflow: visible;

  .container-fluid {
    width: 100%;
    height: 100%;

    ::v-deep .title-homework {
      margin-bottom: 0;
    }

    .container-scroller {
      width: 100%;
      height: calc(100% - 125px);
      overflow: auto auto;
      padding-bottom: 1px;
    }

    .sub-functions-area {
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      margin-right: 1.0rem;
      .sub-functions {
        position: absolute;
        font-size: var(--bs-body-font-size);
        .button-leader {
          cursor: pointer;
          width: 30px;
          height: 30px;
          background-color: var(--bs-table-header);
          color: var(--bs-layout-theme);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-weight: bold;
        }

        .sub-menu-list {
          position: absolute;
          width: 160px;
          height: auto;
          top: 38px;
          right: 0px;
          z-index: 20;
          text-align: left;

          .sub-menu-item {
            cursor: pointer;
            font-weight: 900;
            padding: 10px 20px;
            background-color: #FFF;
            border-left: solid var(--bs-layout-theme) 2px;
            border-right: solid var(--bs-layout-theme) 2px;
            border-bottom: solid var(--bs-layout-theme) 1px;

            &:first-child {
              border-top: solid var(--bs-layout-theme) 2px;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }

            &:last-child {
              border-bottom: solid var(--bs-layout-theme) 2px;
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
            }

            &:hover {
              background-color: var(--bs-table-row);
            }
          }
        }
      }
    }

  }
}
.homework-name {
  font-size: 120%;
  font-weight: bold;
}

.bulk-return {
  display: flex;
  height: auto;
  // position: relative;

  .bulk-return-button {
    &.hide {
      visibility: hidden;
    }
  }

  .bulk-return-menu {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;

    .bulk-return-menu-frame {
      position: relative;
      display: flex;
      border-radius: 6px;
      display: flex;
      align-items: center;

      &> * {
        padding-right: 6px;
      }

      .bulk-return-menu-title{
        position: absolute;
        height: 20px;
        top: -10px;
        left: 20px;
        padding: 0 10px;
        background-color: #fff;
      }
    }
  }
}

</style>
