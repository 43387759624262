<template>
  <div
    class="homework"
    style="height: unset;"
  >
    <div class="mt-3 mx-4 mb-2">
      <TitleHomework
        :help-link="$t('url.helps.homeworkList')"
        :show-buttons="true"
        @create-homework="createHomework"
        @export-homework="onClickExportHomework"
        @set-eval="showSettingEval=true;"
      />
    </div>
    <div class="container-scroller">
      <div class="container-fluid">
        <div class="top-contents">
          <HomeworkFilterCondition
            v-if="homeworkItems.length > 0"
            :homework-list="homeworkItems"
            @change-filter="change_filter_handler"
          />
        </div>
        <div
          ref="tableHomeworkTop"
          class="row justify-content-center"
        >
          <div class="col">
            <div v-if="homeworkItems.length > 0">
              <TableHomework
                v-if="filteredHomeworkItems.length > 0"
                :homework-list="filteredHomeworkItems"
                @delete-homework="deleteHomework"
              />
              <div
                v-if="filteredHomeworkItems.length == 0"
                class="d-flex justify-content-center align-items-center pt-5"
                style="font-size: 1.2em;">
                検索条件に該当する宿題が見つかりませんでした。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTerms
      v-if="showModal == 'terms'"
      :is-agree-required="true"
      @agree-terms="agreeTerms()"
      @disagree-terms="$router.push({ name: 'Login' })"
    />

    <ModalNotice
      v-if="showModal == 'notice'"
      :notice-title="noticeTitle"
      :notice-style="noticeStyle"
      :notice-text="noticeText"
      @close-notice="closeNotice()"
    />

    <ModalUpdateInfo
      v-if="showModal == 'updateInfo'"
      :update-info-text="updateInfoText"
      @close-update-info="closeUpdateInfo()"
    />
    
    <ModalConfirmOkOnly
      v-if="showComfirm"
      :message="comfirmMessage"
      :font-size="confirmFontSize"
      @close-confirm-modal-ok-only="showComfirm = false"
    />

    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />

    <ModalConfirmOkOnly
      v-if="showServerErrorExportHomework"
      :message="$t('messages.error.serverErrorExportHomework')"
      :font-size="confirmFontSize"
      @close-confirm-modal-ok-only="onClickExportHomeworkErrorModalOkButton"
    />

    <ModalConfirmWithTitle
      v-if="showExportHomeworkConfirm"
      :message="$t('labels.exportHomeworkDialog.message')"
      ok-button-id="buttons.output"
      :z-index="99"
      @ok-confirm-modal="exportHomework"
      @cancel-confirm-modal="showExportHomeworkConfirm = false"
    />

    <ModalSettingEval
      v-if="showSettingEval"
      :initial-data="evalSet"
      :is-common="true"
      v-on="{
        set: settingEvalSetHandler,
        cancel: settingEvalCloseHandler,
      }"
    />

    <LoadingScreen v-if="isLoading" />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題一覧
 */
import { db, funcs } from "@/dbs/indexedDb"
import { mapMutations } from "vuex"
import axios from "axios"
import mixin from "../mixins/mixin"
import { streamStatus } from "@/constant/homework"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import TableHomework from "@/components/organisms/TableHomework.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import ModalConfirmWithTitle from "../components/organisms/modal/ModalConfirmWithTitle.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import homeworkRepository from "../repositories/homework"
import { apiResponseCode, sukenServiceId } from "../constant/network"
import accountRepository from "../repositories/account"
import HomeworkFilterCondition from "@/components/organisms/HomeworkFilterCondition.vue"
import ModalSettingEval from "@/components/organisms/modal/ModalSettingEval.vue"
import ModalTerms from "@/components/organisms/modal/ModalTerms.vue"
import ModalNotice from "@/components/organisms/modal/ModalNotice.vue"
import ModalUpdateInfo from "@/components/organisms/modal/ModalUpdateInfo.vue"
import { termsVersion } from "../constant/config"

export default {
  name: "Homework",
  components: {
    TitleHomework,
    TableHomework,
    ModalConfirmOkOnly,
    ModalConfirmWithTitle,
    ModalTerms,
    ModalNotice,
    ModalUpdateInfo,
    LoadingScreen,
    HomeworkFilterCondition,
    ModalSettingEval,
},

  mixins: [mixin],

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    // 宿題一覧
    homeworkItems: [],
    filteredHomeworkItems: [],
    showComfirm: false,
    comfirmMessage: "",
    confirmFontSize: undefined,
    isLoading: false,
    // 宿題共通設定
    evalSet: {},
    categorySet: null,
    categoryList: [],
    showSubMenu: false,
    showExportHomeworkConfirm: false,
    showSettingEval: false,
    filterCondition: {},
    showServerErrorExportHomework: false,
    showModal: "",
    systemVersion: "",
    initViewList: [],   // 起動時に表示する画面のリスト
    noticeVersion: "",
    noticeTitle: "",
    noticeStyle: "",
    noticeText: "",
    updateInfoText: "",
  }),

  async mounted() {
    // store をクリアする
    this.clearHeaderInfo()
    this.clearHomeworkDetailItems()
    // this.clearHomeworkFileHistoryItems()
    this.clearHomeworkSetItems()

    // セッションチェック
    if (!await this.checkSession()) {
      return
    }

    if (!await this.onRefleshGroup()) {
      return
    }

    // 起動時に表示する画面リストの設定
    this.setInitViewList();

    // 宿題一覧を取得
    await this.getHomeworkList()

    // 宿題共通設定を取得
    await this.getHomeworkCmnInfo()

    await this.init()
  },

  methods: {
    /**
     * 初期処理
     */
    async init() {
      await this.onInitCheckGroups()
    },
    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },
    ...mapMutations([
      "setGroupIdsOfLoginUser",
      "setAgreedTermsVersion",
      "setViewedNoticeVersion",
      "setvViewedUpdateInfoVersion",
    ]),
    ...mapMutations("homework", [
      "clearHeaderInfo",
      "clearHomeworkDetailItems",
      "clearHomeworkFileHistoryItems",
      "clearHomeworkSetItems",
      "deleteCheckedStudentList",
      "setCategoryItems",
    ]),

    /**
     * 配信先の選択済リストを初期化
     */
    async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
    },

    /**
     * 宿題一覧を取得する
     */
    async getHomeworkList() {
      this.isLoading = true
      try {
        const data = await homeworkRepository.getHomeworkList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.lmsApiToken
        )
        this.homeworkItems = data
        this.filteredHomeworkItems = data.concat()
      } catch (error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    /**
     * 宿題の新規作成
     */
    createHomework: function () {
      if (
        homeworkRepository.isLimitHomeworkCount(
          this.homeworkItems,
          this.paramCreateHomeworkMaximumLimitItems
        )
      ) {
        this.showConfirmDialog(this.$t("messages.error.overLimitHomeworkCount"))
        return
      }
      this.$router.push({ name: "HomeworkRegister" })
    },
    /**
     * 宿題を1件削除する
     * @param {Number} homeworkKey 宿題キー
     * @param {String} schoolId 学校 ID
     */
    async deleteHomework(schoolId, homeworkKey) {
      this.isLoading = true
      // セッション期間が有効かチェックする
      await this.checkSession()

      await homeworkRepository
        .deleteHomework(
          this.loginUserInfo.accountId,
          schoolId,
          homeworkKey,
          this.loginUserInfo.lmsApiToken
        )
        .then(async () => {
          // 処理が成功している場合は、response.data は null

          // Storeに保持している進捗確認情報も削除
          this.deleteCheckedStudentList(homeworkKey)

          // レスポンスキャッシュを全てクリアする
          await funcs.responseCache.clearAll();

          // 宿題一覧を再表示
          this.getHomeworkList()
        })
        .catch((error) => {
          this.isLoading = false
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        })
    },
    /**
     * 確認ダイアログを表示する
     */
    showConfirmDialog: function (message, fontSize = undefined) {
      this.comfirmMessage = message
      this.confirmFontSize = fontSize
      this.showComfirm = true
    },
    /**
     * グループが削除されている宿題を自動で削除する
     */
     async onInitCheckGroups() {
      let deleteItems = []
      for (const item of this.homeworkItems) {
        let groupCheck = item.groupId && !this.loginUserInfo.groupIds.some(
          (group) => group.groupId === item.groupId
        )
        // グループが存在しない場合
        if (groupCheck) {
          // グループを再取得し最終確認（別画面でグループ・宿題が作成されたケースを考慮）
          await this.onRefleshGroup()
          groupCheck = item.groupId && !this.loginUserInfo.groupIds.some(
            (group) => group.groupId === item.groupId
          )
          // グループが存在しない場合（確定）、削除リストに追加
          if (groupCheck) {
            deleteItems.push({
              schoolId: item.schoolId,
              homeworkKey: item.homeworkKey,
            })
          }
        }
      }
      // グループが削除されている宿題を削除
      if (deleteItems.length > 0) {
        deleteItems.map(async (item) => {
          await this.deleteHomework(item.schoolId, item.homeworkKey)
        })
      }
    },
    /**
     * 宿題共通設定を取得する
     */
    async getHomeworkCmnInfo() {
      this.isLoading = true

      this.evalSet = {}
      this.categorySet = {}
      this.categoryList = []
      try {
        const data = await homeworkRepository.getHomeworkCmnInfo(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.accountId,
        )
        if (data === null) {
          // 宿題共通設定を新規登録
          this.evalSet = {
            eval_set_flg: 1,
            detail_set_flg: 0,
            handed_eval: 1,
            handed_expired_eval: 2,
            send_back_eval: 3,
            not_handed_eval: 4,
            note_handed_eval: 1,
            note_handed_expired_eval: 2,
            note_send_back_eval: 3,
          }
          this.categorySet = {
            category_list: ["通常課題","試験前課題","長期休暇課題","小テスト"],
          }
          await this.updateHomeworkCmnSet(this.evalSet, this.categorySet)
        } else {
          this.evalSet = data.evalSet
          this.categorySet = data.categorySet
        }
        this.categoryList = this.categorySet.category_list
        this.setCategoryItems([...this.categorySet.category_list])
        this.categoryList.push("未設定")
      } catch (error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.isLoading = false
      }
    },

    /**
     * 宿題共通設定を更新する
     * @param {Object} evalSet 評価設定
     * @param {Object} categorySet カテゴリー設定
     */
    async updateHomeworkCmnSet(evalSet, categorySet) {
      try {
        await homeworkRepository.updateHomeworkCmnSet(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.accountId,
          evalSet,
          categorySet,
        );
      } catch (error) {
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
      }
    },

    /**
     * フィルター変更後ハンドラ
     * @param {array} filteredList フィルター適用後宿題リスト
     */
    change_filter_handler(filteredList, condition) {
      // フィルター条件保持
      this.filterCondition = condition;
      // フィルター適用後宿題リストを更新
      this.filteredHomeworkItems.splice(0, this.filteredHomeworkItems.length, ...filteredList);
    },

    /** エクスポート対象の宿題リストを取得 */
    getExportHomeworkItems() {
      return this.filteredHomeworkItems.filter(
        (v) => v.status === streamStatus.streaming || v.status === streamStatus.endOfStream
      )
    },

    /** 宿題エクスポート */
    async exportHomework() {
      this.isLoading = true;
      // セッションチェック
      if (!await this.checkSession()) {
        this.isLoading = false;
        return
      }
      const param = {
        "account_id": this.loginUserInfo.accountId,
        "school_id": this.loginUserInfo.schoolId,
        "token": this.loginUserInfo.lmsApiToken,
        "session_token": this.loginUserInfo.sessionToken,
        "homework_key_list": this.getExportHomeworkItems().map(item=>item.homeworkKey),
        "search_conditions": this.filterCondition,
      };
      try {
        let result = await homeworkRepository.exportHomework(param);
        let executionArn = result.data.executionArn;
        const checkStatus = async () => {
          try {
            let statusResult = await homeworkRepository.getStatusForExportHomework(executionArn)
            let status = statusResult.data.status;
            switch(status) {
              case "RUNNING":
                // 実行継続中
                break;
              case "SUCCEEDED":
                // 実行終了(成功)
                {
                  this.isLoading = false;
                  // 出力URLでファイルダウンロード
                  const output = JSON.parse(statusResult.data.output)
                  const exportFileUrl = output.data.export_file_url
                  const fileName = decodeURI(exportFileUrl).split("?")[0].split("/").pop();

                  const a = document.createElement('a');
                  a.href = exportFileUrl;
                  a.download = fileName;
                  a.click();
                  a.remove();
                }
                break;
              case "FAILED":
                // 実行終了(失敗)
                this.isLoading = false;
                this.showServerErrorExportHomework = true;
                break;
            }
          } catch(error) {
            this.isLoading = false;
            this.$router.push({
              name: "APIError",
              params: { status: error.status },
            })
          }
          return this.isLoading;
        }

        // ステータスチェック(終了まで待機)
        if (await checkStatus()) {
          const intervalid = setInterval(async () => {
            if (!await checkStatus()) {
              this.showExportHomeworkConfirm = false;
              clearInterval(intervalid);
            }
          }, 2000);
        }
      } catch(error) {
        this.isLoading = false;
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
      }
    },

    /** エクスポートエラーモーダルOK押下時 */
    onClickExportHomeworkErrorModalOkButton() {
      this.showServerErrorExportHomework = false;
      // グループ更新（ヘッダのグループ更新処理呼び出し）
      this.$parent.refreshGroupList();
    },

    /** 宿題評価の一括設定 設定ハンドラ */
    async settingEvalSetHandler(evalSet) {
      this.isLoading = true
      try {
        // セッションチェック
        if (!await this.checkSession()) {
          return
        }
        // 宿題共通設定保存API(カテゴリーは設定しない)
        await this.updateHomeworkCmnSet(evalSet, undefined)
        this.evalSet = evalSet
      } catch (error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.showSettingEval = false
        this.isLoading = false
      }
    },
    settingEvalCloseHandler() {
      this.showSettingEval = false
    },
    /**
     * 「宿題の記録を一括出力」押下
     */
    async onClickExportHomework() {
      if (this.getExportHomeworkItems().length === 0){
        this.showConfirmDialog(this.$t('messages.error.notExistExportHomework'), "1.1rem")
        return;
      }
      this.showSubMenu=false
      this.showExportHomeworkConfirm=true
    },
    /**
     * グループ再取得処理
     */
    async onRefleshGroup() {
      let groupIdPromise = null
      try {
        groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
          sukenServiceId.lms,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
        if (groupIdPromise.data.status === apiResponseCode.forbidden) {
          this.setSessionDisconnect(true)
          return
        }
      } catch (error) {
        if (error.status === apiResponseCode.forbidden) {
          this.setSessionDisconnect(true)
          return false
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
        return false
      }

      // グループIDを store に登録
      this.setGroupIdsOfLoginUser(groupIdPromise.data.groupList)
      return true
    },
    /**
     * 起動時に表示する画面リストの設定
     */
    async setInitViewList() {
      // システムバージョンを取得
      let sysVer = await this.downloadFile('/system_version.json');
      if (sysVer != null) {
        this.systemVersion = sysVer.system_version;
      }

      // 利用規約設定
      await this.setTerms();
      // お知らせ設定
      await this.setNotice();
      // 更新情報設定
      await this.setUpdateInfo();

      this.showModal = this.initViewList.shift();
    },
    /**
     * 利用規約モーダル設定
     */
    async setTerms() {
      // 最新の利用規約に同意していない場合、利用規約を表示
      if (this.systemInfo.agreedTermsVersion == '' || parseInt(this.systemInfo.agreedTermsVersion) < parseInt(termsVersion)) {
        this.initViewList.push('terms');
      }
    },
    /**
     * お知らせモーダル設定
     */
    async setNotice() {
      let result = false;

      // お知らせ情報を取得
      let noticeInfo = await this.downloadFile('/info/notice/notice_settiing.json');
      
      // 最新のファイルを閲覧していない場合
      if ((noticeInfo != null && noticeInfo.notice_version != '') && (this.systemInfo.viewedNoticeVersion == '' || parseInt(this.systemInfo.viewedNoticeVersion) < parseInt(noticeInfo.notice_version))) {
          this.noticeVersion = noticeInfo.notice_version;

          // 公開期間が設定されていない場合表示
          if (noticeInfo.notice_start_date == '' && noticeInfo.notice_end_date == '') {
              result = true;
          } else {
              try {
                // システム日付を取得
                let systemDate = new Date(await homeworkRepository.getSystemDate(
                  this.loginUserInfo.accountId,
                  this.loginUserInfo.schoolId,
                  this.loginUserInfo.lmsApiToken
                ))
                // 公開期間中の場合表示
                if ((noticeInfo.notice_start_date == '' || systemDate >= new Date(noticeInfo.notice_start_date)) && (noticeInfo.notice_end_date == '' || systemDate <= new Date(noticeInfo.notice_end_date))) {
                    result = true;
                }
              } catch (response) {
                  console.info("getSystemDate", response)
              }
          }
      }

      if (result) {
        let _str = await this.downloadFile('/info/notice/notice.txt');
        if (_str != null && _str != "") {
          this.noticeTitle = noticeInfo.notice_title_text;
          this.noticeStyle = noticeInfo.notice_title_style.replaceAll(',',' ');
          this.noticeText = this.convertInitViewText(_str);
          this.initViewList.push('notice');
        } else {
          // 閲覧済お知らせバージョンを store に登録
          this.setViewedNoticeVersion(this.noticeVersion);
        }
      }
    },
    /**
     * 更新情報モーダル設定
     */
    async setUpdateInfo() {
      // 最新のファイルを閲覧していない場合表示
      if (this.systemVersion != '' && (this.systemInfo.viewedUpdateInfoVersion == '' || parseInt(this.systemInfo.viewedUpdateInfoVersion) < parseInt(this.systemVersion))) {
        let _str = await this.downloadFile('/info/update/update_info.txt');
        if (_str != null && _str != "") {
          this.updateInfoText = this.convertInitViewText(_str);
          this.initViewList.push('updateInfo');
        } else {
          // 閲覧済更新情報バージョンを store に登録
          this.setvViewedUpdateInfoVersion(this.systemVersion);
        }
      }
    },
    /**
     * 起動時に表示する画面のテキストを変換
     */
     convertInitViewText: function (str) {
        // URLをaタグに変換
        // eslint-disable-next-line
        str = str.replaceAll(/(https?:\/\/[^\s　）]+)/g, '<a href="$1" target="_blank">$1</a>');
        // 改行をbrタグに変換
        str = str.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');
        return str;
    },
    /**
     * 利用規約モーダル同意ボタン押下
     */
    async agreeTerms() {
      // 同意済利用規約バージョンを store に登録
      this.setAgreedTermsVersion(termsVersion);
      this.showModal = this.initViewList.shift();
    },
    /**
     * お知らせモーダル閉じるボタン押下
     */
    async closeNotice() {
      // 閲覧済お知らせバージョンを store に登録
      this.setViewedNoticeVersion(this.noticeVersion);
      this.showModal = this.initViewList.shift();
    },
    /**
     * 更新情報モーダル閉じるボタン押下
     */
    async closeUpdateInfo() {
      // 閲覧済更新情報バージョンを store に登録
      this.setvViewedUpdateInfoVersion(this.systemVersion);
      this.showModal = this.initViewList.shift();
    },
    /**
     * ファイルダウンロード
     */
    async downloadFile(url) {
      try {
        let response = await axios.get(url, {
          timeout: 5000,
        })
        return response.data;
      } catch (ex) {
        return null;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.homework {
  overflow: visible;
}

.container-scroller {
  width: 100%;
  height: calc(100% - 42px);
  overflow: auto auto;
}

.top-contents {
  position: relative;
}

</style>
