<template>
  <div class="question">
    <div class="d-flex">
      <div class="flex-grow-1 px-4 py-3 bd-highlight">
        <div class="mb-4">
          <TitleQuestion />
        </div>
        <div class="d-flex align-item-center mt-3 mb-4">
          <div class="me-3 group-text-area">
            <FormSelectBox
              id="selectedGroupId"
              ref="groupSelectBox"
              :label-text="$t('labels.group')"
              :options="groupSelect"
              initial-value="0"
              @input="onChangeGroup"
            />
          </div>
          <div>
            <FormSelectBox
              id="selectedBookId"
              ref="bookSelectBox"
              :label-text="$t('labels.textbook')"
              :options="bookSelect"
              initial-value="0"
              @input="onChangeBook"
            />
          </div>
        </div>
        <div
          v-if="showCanNotChangeTextbookFlg"
          class="text-danger"
        >
          {{ $t("messages.error.canNotChangeSelectedTextbook") }}
        </div>
        <div
          v-if="showCanNotGetTextbook"
          class="text-danger"
        >
          {{ $t("messages.error.canNotGetTextbook") }}
        </div>
        <div
          v-if="showCanNotGetStudent"
          class="text-danger"
        >
          {{ $t("messages.error.canNotGetStudent") }}
        </div>
        <div v-if="isSelectedDropDownList && !showCanNotChangeTextbookFlg">
          <div v-if="filterList">
            <div>
              <label class="form-label">設定項目</label>
              <ul
                v-if="filterList"
                class="d-flex mb-4"
              >
                <SettingFilterTab
                  v-for="item in filterList"
                  :key="item.id"
                  v-model="currentId"
                  v-bind="item"
                  class="setting-filter"
                  @input="onChangedTab"
                />
              </ul>
            </div>
            <div class="ques-wrap justify-content-center row">
              <div class="col-4 align-self-stretch tree-wrap">
                <TreeQuestion
                  :key="currentId"
                  ref="treeQuestion"
                  class="tree"
                  :list="questionLists[currentId]"
                  :display-all-check-flg="true"
                  :grayout-hidden-mode="true"
                  v-on="{
                    'clicked-question-title': onClickedQuestionTitle,
                    'change-preview-tabs': changePreviewAreaTabs,
                    'change-display-message': onChangeMessageDisplayFlag,
                    'clicked-select-all': onClickSelectAll,
                    'on-dirty': onDirty,
                  }"
                />
              </div>
              <PreviewContainer
                ref="previewContainer"
                :image="image"
                :setting-list="settingList"
                :message-display-flg="messageDisplayFlg"
              />
              <div class="row mb-1">
                <div class="col">
                  <label
                    class="text-end w-100"
                    style="font-size: 85%"
                  >
                    {{ $t("labels.questionManagementMessage") }}
                  </label>
                </div>
              </div>
              <div class="row gx-5 justify-content-center mt-4">
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    label-name="キャンセル"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    @click.native="onCancel"
                  />
                </div>
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    label-name="登録"
                    color="layout-theme-light"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    :disabled="!filterList"
                    @click.native="onRegisterQuestion"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            {{ $t("messages.error.notChangeSettingsSelectedMaterial") }}
          </div>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
    <ModalConfirmOkOnly
      v-if="showChangedGroups"
      :message="$t('messages.error.changedGroup')"
      @close-confirm-modal-ok-only="onOkCloseShowChangedGroups()"
    />
    <ModalConfirmOkOnly
      v-if="displayRegisterWithCaution"
      :message="$t('messages.success.registerWithCaution')"
      @close-confirm-modal-ok-only="
        () => {
          displayRegisterWithCaution = false
        }
      "
    />
    <ModalConfirm
      v-if="displayConfirmBookChange"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onChangeBookConfirmOk,
        'cancel-confirm-modal': onChangeBookConfirmCancel,
        'close-confirm-modal': () => {
          displayConfirmBookChange = false
        },
      }"
    />
    <ModalConfirm
      v-if="displayConfirmGroupChange"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onChangeGroupConfirmOk,
        'cancel-confirm-modal': onChangeGroupConfirmCancel,
        'close-confirm-modal': () => {
          displayConfirmGroupChange = false
        },
      }"
    />
  </div>
</template>

<script>
import mixin from "@/mixins/mixin"
import { db, funcs } from "@/dbs/indexedDb"
import { mapGetters, mapMutations } from "vuex"
import questionRepository from "@/repositories/question"
import questionObjectGeneratorRepository from "@/repositories/questionObjectGenerator"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import FormSelectBox from "@/components/atoms/FormSelectBox.vue"
import SettingFilterTab from "@/components/atoms/SettingFilterTab.vue"
import TitleQuestion from "@/components/molecules/TitleQuestion.vue"
import TreeQuestion from "@/components/organisms/TreeQuestion.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import accountRepository from "@/repositories/account"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import { displayFlagCode, settingTabIdCode } from "@/constant/question.js"
import { sukenServiceId, apiResponseCode } from "@/constant/network"
import PreviewContainer from "@/components/organisms/PreviewContainer.vue"

export default {
  name: "Question",
  components: {
    TitleQuestion,
    FormSelectBox,
    SettingFilterTab,
    ButtonBorderCircleMedium,
    TreeQuestion,
    LoadingScreen,
    PreviewContainer,
    ModalConfirm,
    ModalConfirmOkOnly,
  },

  mixins: [mixin],

  data: () => ({
    questionLists: [],
    // 初期状態のquestionLists
    // 「キャンセル」押下時に状態を復元するために使用する
    prevQuestionLists: [],
    currentId: -1,
    selectedGroupId: "",
    groupSelect: [],
    selectedBookId: "",
    bookSelect: [],
    filterList: [],
    settingList: null,
    // ３タブ情報
    defaultSettingList: [],
    image: null,
    isLoading: false,
    studentItems: [],
    bookItems: [],
    bookAndAccount: {},
    showCanNotChangeTextbookFlg: false,
    showCanNotGetTextbook: false,
    showCanNotGetStudent: false,
    showChangedGroups: false,
    isDirty: false,
    messageDisplayFlg: true,
    stateGroupIds: false,
    stateGroupAccounts: false,
    displayRegisterWithCaution: false,
    confirmBookId: 0,
    displayConfirmBookChange: false,
    confirmGroupId: 0,
    displayConfirmGroupChange: false,
  }),

  computed: {
    ...mapGetters("nameConversions", ["curriculumConversionTable"]),
    /**
     * グループ・書籍ドロップダウンリストの項目選択状態を真偽で返す処理（選択時は真）
     */
    isSelectedDropDownList() {
      let isAnyChecked = false
      if (this.selectedGroupId === "0" || this.selectedGroupId === "") {
        isAnyChecked = true
      }

      if (this.selectedBookId === "0" || this.selectedBookId === "") {
        isAnyChecked = true
      }

      if (isAnyChecked) {
        return false
      }

      return true
    },
  },

  async mounted() {
    this.isLoading = true
    // グループ更新（ヘッダのグループ更新処理呼び出し）
    await this.$parent.refreshGroupList();
    this.getGroupSelect()
    this.getBookList([])

    this.isLoading = false
  },

  methods: {
    ...mapMutations([
      "setGroupIdsOfLoginUser",
      "setHandleCurriculumsOfLoginUser",
    ]),
    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },
    /**
     * 配信先の選択済リストを初期化
     */
    async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
    },
    async getSukenAccountWithBooks(selectedGroupId) {
      let curriculums = []
      this.nameCurriculums.shortName.map((item) => {
        for (let i in this.loginUserInfo.handleCurriculums) {
          if (this.loginUserInfo.handleCurriculums[i] == item.code) {
            curriculums.push(item.name)
          }
        }
      })
      try {
        return await accountRepository.getSukenAccountWithBooks(
          selectedGroupId,
          this.loginUserInfo.accountId,
          curriculums,
          this.loginUserInfo.sessionToken,
          this.paramApiSettingItems.find(v => v.itemName === 'accountsProductsBooks').items
        )
      } catch (error) {
        return error
      }
    },
    getBookList(bookItems) {
      this.bookSelect = accountRepository.getBookList(bookItems)
    },
    async getSettingQuestionInfo() {
      let checkArrays = new Object()
      try {
        checkArrays = await questionRepository.getSettingQuestionInfo(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          Number(this.selectedGroupId),
          this.selectedBookId,
          this.loginUserInfo.accountId
        )
      } catch (err) {
        console.log(err)
      }
      return checkArrays
    },
    /**
     * ログイン時に取得したグループリストを変換して取得する処理
     */
    getGroupSelect() {
      this.groupSelect = questionRepository.getGroupSelect(
        this.loginUserInfo.groupIds
      )
    },
    /**
     * 問題リストにチェックフラグ、表示制御フラグ、インデックスを追加
     */
    generateQuestionList(list, defaultValue) {
      if (!list) {
        return
      }

      // defaultValueに入っている値はundefinedまたは文字列（数値ではない）
      const check =
        defaultValue !== undefined ? defaultValue === "1" : undefined
      list.forEach((parent, parentIndex) => {
        this.$set(parent, "checkFlg", check)
        this.$set(parent, "isOpen", false)
        this.$set(parent, "parentIndex", parentIndex)
        parent.nodes.forEach((child, childIndex) => {
          this.$set(child, "checkFlg", check)
          this.$set(child, "isOpen", false)
          this.$set(child, "parentIndex", parentIndex)
          this.$set(child, "childIndex", childIndex)
          child.nodes.forEach((element, elementIndex) => {
            this.$set(element, "checkFlg", check)
            this.$set(element, "isOpen", false)
            this.$set(element, "bookId", this.selectedBookId)
            this.$set(element, "parentIndex", parentIndex)
            this.$set(element, "childIndex", childIndex)
            this.$set(element, "elementIndex", elementIndex)
            if ("nodes" in element) {
              element.nodes.forEach((tag, tagIndex) => {
                this.$set(tag, "isOpen", false)
                this.$set(tag, "bookId", this.selectedBookId)
                this.$set(tag, "parentIndex", parentIndex)
                this.$set(tag, "childIndex", childIndex)
                this.$set(tag, "elementIndex", elementIndex)
                this.$set(tag, "tagIndex", tagIndex)
              })
            }
          })
        })
      })
    },
    /**
     * 引数の教科、教材に紐づく問題一覧を取得する処理
     */
    async getTextbookConfigJson(curriculum, bookId) {
      return await questionRepository.getTextbookConfigJson(curriculum, bookId)
    },
    /**
     * 引数の教科、教材に紐づくコンテンツリストを取得する処理
     */
    async getContentsListJson(curriculum, bookId) {
      try {
        return await questionRepository.getContentsListJson(curriculum, bookId)
      } catch (error) {
        if (error.status === apiResponseCode.notFound) {
          return []
        } else {
          return error
        }
      }
    },
    /**
     * グループ ID に紐づくアカウントが所有している教材パッケージの閲覧権限を取得する
     */
    async getCookieSukenAccountWithBooks(
      groupId,
      studentAccountId,
      productId,
      bookId,
      teacherAccountId,
      sessionToken
    ) {
      return await accountRepository.getCookieSukenAccountWithBooks(
        groupId,
        studentAccountId,
        productId,
        bookId,
        teacherAccountId,
        sessionToken
      )
    },
    /**
     * グループリスト選択変更時処理
     */
    async onChangeGroup(selectedGroupId) {
      this.isLoading = true

      if (this.showChangedGroups) {
        return
      }

      this.confirmGroupId = selectedGroupId

      this.isLoading = false
      if (this.isDirty) {
        this.displayConfirmGroupChange = true
        return
      }
      this.onChangeGroupConfirmOk()
    },
    /**
     * グループリスト選択変更時の確認ダイアログでOKが押された
     */
    async onChangeGroupConfirmOk() {
      this.isDirty = false
      this.isLoading = true

      this.selectedGroupId = this.confirmGroupId

      this.selectedBookId = "0"
      this.showCanNotChangeTextbookFlg = false
      this.showCanNotGetTextbook = false
      this.showCanNotGetStudent = false
      if (this.selectedGroupId === "0") {
        this.isLoading = false
        this.getBookList([])
        return
      }

      // 教材パッケージ一覧のレスポンスキャッシュをクリア
      await funcs.responseCache.clearMatch("/accounts/products/books");

      try {
        // グループ変更チェック
        this.stateGroupIds = await accountRepository.getChangeStateGroupIds(
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken,
          this.selectedGroupId
        )
        this.showChangedGroups = this.stateGroupIds
        if (this.showChangedGroups) {
          return
        }

        const getSukenAccountWithBooksPromise =
          await this.getSukenAccountWithBooks(this.selectedGroupId)
        
        if (getSukenAccountWithBooksPromise && getSukenAccountWithBooksPromise.status === apiResponseCode.forbidden) {
          this.isLoading = false
          this.setSessionDisconnect(true)
          return
        }

        this.studentItems = getSukenAccountWithBooksPromise.accountItems
        this.bookItems = getSukenAccountWithBooksPromise.bookItems

        // 紐づく教材が0件の場合
        if (this.bookItems.length === 0) {
          this.showCanNotGetTextbook = true
          this.getBookList([])
          return
        }

        this.getBookList(this.bookItems)

        if (this.isSelectedDropDownList) {
          await this.onSelectedDropDownList()
        }
      } catch (error) {
        // 紐づく生徒が0件の場合
        this.showCanNotGetStudent = true
        this.getBookList([])

        if (!await this.checkSessionFromResponse(error)) {
          return
        }
      } finally {
        this.isLoading = false
      }
    },
    /**
     * グループリスト選択変更時の確認ダイアログでキャンセルが押された
     */
    onChangeGroupConfirmCancel() {
      this.$refs.groupSelectBox.setValue(this.selectedGroupId)
    },
    /**
     * 書籍リスト選択変更時処理
     */
    async onChangeBook(selectedBookId) {
      this.isLoading = true

      // セッション期間が有効かチェックする
      await this.checkSession()

      if (this.selectedGroupId && this.selectedGroupId !== "0") {
        // 選択したグループが変更されているかを検知する
        await this.getChangeStateGroups()
      }

      if (this.showChangedGroups) {
        this.isLoading = false
        return
      }

      this.confirmBookId = selectedBookId
      if (this.isDirty) {
        this.isLoading = false
        this.displayConfirmBookChange = true
        return
      }
      this.onChangeBookConfirmOk()
    },
    /**
     * 書籍リスト変更時の確認ダイアログでOKが押された
     */
    async onChangeBookConfirmOk() {
      this.isDirty = false
      this.selectedBookId = this.confirmBookId
      this.isLoading = true

      // 諸々初期化
      this.image = null
      this.settingList = null
      this.filterList.splice(0)
      this.questionLists.splice(0)
      this.showCanNotChangeTextbookFlg = false
      this.showCanNotGetTextbook = false
      this.showCanNotGetStudent = false

      try {
        if (this.isSelectedDropDownList) {
          this.bookAndAccount = accountRepository.getBookAndStudentAccount(
            this.studentItems,
            this.bookItems,
            this.selectedBookId
          )
          // 教材パッケージの閲覧権限を取得する処理
          await this.getCookieSukenAccountWithBooks(
            this.selectedGroupId,
            this.bookAndAccount.student.accountId,
            this.bookAndAccount.book.productIds[0],
            this.selectedBookId,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
          await this.onSelectedDropDownList()
        }
      } catch (error) {
        this.showCanNotChangeTextbookFlg = true
      } finally {
        this.isLoading = false
      }
    },
    /**
     * 書籍リスト変更時の確認ダイアログでキャンセルが押された
     */
    onChangeBookConfirmCancel() {
      this.$refs.bookSelectBox.setValue(this.selectedBookId)
    },
    /**
     * グループ・教材の両方が選択された時の処理
     */
    async onSelectedDropDownList() {
      this.isLoading = true
      this.settingList = null

      // 「すべて選択」のチェックボックスを問答無用で外す
      // ※le_ques_config.jsonが存在しない教材を選択していた場合はtreeQuestionを参照できないので、チェックが必要
      if (this.$refs.treeQuestion) {
        this.$refs.treeQuestion.uncheckAllCheckBox()
      }
      this.$refs.previewContainer.initializeId()

      try {
        // 問題一覧を取得する処理
        const textbookConfigPromise = await this.getTextbookConfigJson(
          this.bookAndAccount.book.shortCurriculumName,
          this.selectedBookId
        )
        const textbookConfigResult = textbookConfigPromise.data

        // コンテンツリストを取得する処理
        const contentsListPromise = await this.getContentsListJson(
          this.bookAndAccount.book.shortCurriculumName,
          this.selectedBookId
        )
        const contentsListResult = !contentsListPromise
          ? contentsListPromise
          : contentsListPromise.data

        // 設定項目タブを取得
        const filterTab = questionObjectGeneratorRepository.getDisplayFilterTab(
          textbookConfigResult,
          contentsListResult
        )
        if (!filterTab.length) {
          this.questionLists = []
        }
        this.filterList = filterTab

        if (!textbookConfigResult.tab.default) {
          this.showCanNotChangeTextbookFlg = true

          this.isLoading = false
          return
        }
        const checkArrays = await this.getSettingQuestionInfo()
        this.questionLists = new Array(this.filterList.length)
        const validList = this.filterList.filter(
          (filter) => filter.label !== null
        )
        this.defaultSettingList = new Array(validList.length - 1)
        this.filterList.forEach((filter, index) => {
          const id = filter.id
          const questionList =
            questionObjectGeneratorRepository.generateObjectTree(
              textbookConfigResult,
              contentsListResult,
              id
            )
          this.questionLists.splice(id, 1, questionList)
          // 問題リストにチェックフラグを追加
          this.generateQuestionList(
            this.questionLists[id],
            textbookConfigResult.tab.default[id - 1]
          )
          this.checkInitialValues(this.questionLists[id], checkArrays[id])

          // プレビューエリアのタブ構築
          if (filter.label && filter.id !== settingTabIdCode.contentId) {
            const setting = JSON.parse(JSON.stringify(filter))
            setting.nodeItem = null
            this.defaultSettingList.splice(index, 1, setting)
          }
        })
      } catch (error) {
        this.isLoading = false
        if (!error || error.status === apiResponseCode.notFound) {
          this.showCanNotChangeTextbookFlg = true
          return
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
        return
      }

      // 編集前の状態を保存
      // 下記の書き方で値渡しが可能
      // 参考：https://omachizura.com/2016/10/vue-copy-object.html
      this.prevQuestionLists = JSON.parse(JSON.stringify(this.questionLists))

      this.currentId = this.filterList[0].id
    },
    /**
     * 初期のチェック状態を設定する再帰メソッド
     */
    checkInitialValues(list, checkArrays) {
      if (!list) {
        return false
      }

      let isAnyChecked = false
      list.forEach((listItem) => {
        const found =
          checkArrays &&
          Object.keys(checkArrays).filter((item) => item == listItem.id)
            .length > 0
        if (!listItem.nodes) {
          if (listItem.checkFlg === undefined) {
            return
          }
          const isDisplay = listItem.displayFlag === displayFlagCode.display
          if (isDisplay) {
            if (found) {
              this.$set(listItem, "checkFlg", checkArrays[listItem.id].flag)
            }
          } else {
            this.$set(listItem, "checkFlg", false)
          }
          if (listItem.checkFlg) {
            isAnyChecked = true
          }
        } else {
          const checked = this.checkInitialValues(
            listItem.nodes,
            found ? checkArrays[listItem.id] : null
          )
          this.$set(listItem, "checkFlg", checked)
          if (checked) {
            isAnyChecked = true
          }
        }
      })
      return isAnyChecked
    },
    /**
     * 問題名押下時処理
     */
    async onClickedQuestionTitle(item) {
      const suffixList = this.questionLists[this.currentId][0].suffix
      if (this.currentId !== settingTabIdCode.contentId) {
        // ※３タブの場合
        const setting = this.settingList.find((s) => s.id === this.currentId)
        const settingIndex = this.settingList.indexOf(setting)
        this.$set(this.settingList[settingIndex], "nodeItem", item)
        if (suffixList) {
          suffixList.forEach((_, index) => {
            this.$set(this.settingList[index], "parentIndex", item.parentIndex)
            this.$set(this.settingList[index], "childIndex", item.childIndex)
            this.$set(
              this.settingList[index],
              "elementIndex",
              item.elementIndex
            )
            this.$set(
              this.settingList[index],
              "checkFlg",
              this.questionLists[index + 1][item.parentIndex].nodes[
                item.childIndex
              ].nodes[item.elementIndex].checkFlg
            )
          })
        }
      } else {
        if (this.settingList) {
          this.settingList.forEach((_, index) => {
            this.$set(this.settingList[index], "parentIndex", item.parentIndex)
            this.$set(this.settingList[index], "childIndex", item.childIndex)
            this.$set(
              this.settingList[index],
              "elementIndex",
              item.elementIndex
            )
            this.$set(this.settingList[index], "tagIndex", index)
            this.$set(
              this.settingList[index],
              "checkFlg",
              item.nodes[index].checkFlg
            )
            this.$set(
              this.settingList[index],
              "displayFlag",
              item.nodes[index].displayFlag
            )
          })
        }
      }

      // settingListの更新が遅れる事によって生じるチラつきを避ける為、
      // 通信関係は後でまとめて行う

      // 問題の画像を取得
      const imgSrc = item.img
      this.image = await questionRepository.getQuestionImage(imgSrc)

      // previewArea用の画像を取得
      if (this.currentId !== settingTabIdCode.contentId && suffixList) {
        suffixList.forEach(async (suffix, index) => {
          const convertedImgSrc = imgSrc.replace(/\.jpg/, `_${suffix}.jpg`)
          const content = await questionRepository.getQuestionImage(
            convertedImgSrc
          )
          this.$set(this.settingList[index], "content", content)
        })
      }
    },
    /**
     * 表示制御の設定内容登録処理
     */
    onRegisterQuestion: async function () {
      this.isLoading = true
      // セッション期間が有効かチェックする
      if (!await this.checkSession()) {
        return
      }

      if (this.selectedGroupId && this.selectedGroupId !== "0") {
        // 選択したグループが変更されているかを検知する
        await this.getChangeStateGroups()
      }

      if (this.showChangedGroups) {
        return
      }

      try {
        questionRepository.updateQuestionControl(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          Number(this.selectedGroupId),
          this.selectedBookId,
          this.questionLists
        )
        this.prevQuestionLists = JSON.parse(JSON.stringify(this.questionLists))
        this.isDirty = false
        this.displayRegisterWithCaution = true
      } catch (error) {
        if (!error.status) {
          this.isLoading = false
          return
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
      } finally {
        this.isLoading = false
      }
    },
    /**
     * プレビューエリアのタブ差し替え
     */
    changePreviewAreaTabs(item) {
      if (item.tabs) {
        // item.tabsがnullでなければそれを表示
        this.settingList = item.tabs
        this.settingList.forEach(async (tab) => {
          // コンテンツの取得
          // ※セッション確認は後続処理で行う為、ここでは行わない
          tab.content = await questionRepository.getContentImage(
            this.bookAndAccount.book.shortCurriculumName,
            this.selectedBookId,
            tab.content + ".jpg"
          )
        })
        this.$refs.previewContainer.initializeId()
      } else {
        // nullなら３タブを表示
        if (this.settingList && this.settingList.length > 3) {
          // 配列外参照防止用
          // コンテンツ → ３タブに戻った時に起こり得る
          this.$refs.previewContainer.initializeId()
        }
        this.settingList = JSON.parse(JSON.stringify(this.defaultSettingList))
      }
    },
    /**
     * タブが切り替わった
     */
    onChangedTab() {
      this.image = null
      this.settingList = null
    },
    /**
     * 「キャンセル」が押下された
     */
    onCancel() {
      // questionListsを修正前の状態に復元する
      // ※親階層の表示状態も含めて再レンダリングする為、
      //   下に階層のあるノードのcheckFlgには一旦falseを突っ込み、
      //   後でheckInitialValuesメソッドを使って復元する
      this.questionLists.forEach((filter, filterId) => {
        filter.forEach((chapter, chapterIndex) => {
          const prevChapter = this.prevQuestionLists[filterId][chapterIndex]
          this.$set(chapter, "checkFlg", false)
          chapter.nodes.forEach((node, nodeIndex) => {
            const prevNode = prevChapter.nodes[nodeIndex]
            this.$set(node, "checkFlg", false)
            node.nodes.forEach((question, questionIndex) => {
              const prevQuestion = prevNode.nodes[questionIndex]
              if (!question.nodes) {
                // ３タブ
                this.$set(question, "checkFlg", prevQuestion.checkFlg)
              } else {
                // コンテンツ
                this.$set(question, "checkFlg", false)
                question.nodes.forEach((content, contentIndex) => {
                  this.$set(
                    content,
                    "checkFlg",
                    prevQuestion.nodes[contentIndex].checkFlg
                  )
                })
              }
            })
          })
        })
        // 親要素のチェックフラグを反映
        this.checkInitialValues(filter, null)
        this.isDirty = false
      })
    },
    /**
     * チェックが入った時にメッセージの表示、非表示を切り替える
     */
    onChangeMessageDisplayFlag(item) {
      const currentId = this.currentId - 1
      let argument = item
      if (this.settingList === null) {
        return
      }
      // ３タブ
      if (currentId !== settingTabIdCode.contentId - 1) {
        const parentIndex = this.settingList[currentId].parentIndex
        const childIndex = this.settingList[currentId].childIndex
        const elementIndex = this.settingList[currentId].elementIndex
        if (this.hasProperty(item, "suffix")) {
          // 一番親
          argument = item.nodes[childIndex].nodes[elementIndex]
        } else if (
          this.hasProperty(item, "nodes") &&
          !this.hasProperty(item, "img")
        ) {
          // 中間層
          argument = item.nodes[elementIndex]
        }
        // 最下層
        const parentCheck = argument.parentIndex === parentIndex
        const childCheck = argument.childIndex === childIndex
        const elementCheck = argument.elementIndex === elementIndex
        if (parentCheck && childCheck && elementCheck) {
          this.$set(
            this.settingList[currentId],
            "checkFlg",
            this.questionLists[this.currentId][argument.parentIndex].nodes[
              argument.childIndex
            ].nodes[argument.elementIndex].checkFlg
          )
        }
      } else {
        this.settingList.forEach((item) => {
          const parentIndex = item.parentIndex
          const childIndex = item.childIndex
          const elementIndex = item.elementIndex
          const tagIndex = item.tagIndex
          this.$set(
            item,
            "checkFlg",
            this.questionLists[this.currentId][parentIndex].nodes[childIndex]
              .nodes[elementIndex].nodes[tagIndex].checkFlg
          )
        })
      }
    },
    onClickSelectAll(item) {
      // プレビュー画像を表示する前はnullになっている
      if (!this.settingList) {
        return
      }

      const currentId = this.currentId - 1
      if (currentId !== settingTabIdCode.contentId - 1) {
        const parentIndex = this.settingList[currentId].parentIndex
        const childIndex = this.settingList[currentId].childIndex
        const elementIndex = this.settingList[currentId].elementIndex
        this.onChangeMessageDisplayFlag(
          item[parentIndex].nodes[childIndex].nodes[elementIndex]
        )
      } else {
        const parentIndex = this.settingList[currentId].parentIndex
        const childIndex = this.settingList[currentId].childIndex
        const elementIndex = this.settingList[currentId].elementIndex
        this.onChangeMessageDisplayFlag(
          item[parentIndex].nodes[childIndex].nodes[elementIndex]
        )
      }
    },
    hasProperty(item, propName) {
      return propName in item
    },
    /**
     * ツリーに変化が生じた
     */
    onDirty() {
      this.isDirty = true
    },
    /**
     * 選択したグループが変更されているかを取得する
     */
    async getChangeStateGroups() {
      try {
        if (this.selectedGroupId && this.selectedGroupId !== "0") {
          this.stateGroupIds = await accountRepository.getChangeStateGroupIds(
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken,
            this.selectedGroupId
          )
          this.stateGroupAccounts = await this.getChangeStateGroupAccounts()
          this.showChangedGroups = this.stateGroupIds || this.stateGroupAccounts
        }
      } catch (error) {
        if (!await this.checkSessionFromResponse(error)) {
          return
        }
        throw error
      }
    },
    /**
     * グループに紐づく配信先が変更されているかを取得する
     */
    async getChangeStateGroupAccounts() {
      try {
        const getSukenAccountWithBooksPromise =
          await this.getSukenAccountWithBooks(this.selectedGroupId)
        if (!getSukenAccountWithBooksPromise) {
          return true
        }

        if (getSukenAccountWithBooksPromise && getSukenAccountWithBooksPromise.status === apiResponseCode.forbidden) {
          this.isLoading = false
          this.setSessionDisconnect(true)
          return true
        }

        const promiseBooks = getSukenAccountWithBooksPromise.bookItems

        // 紐づく教材が0件の場合
        if (!promiseBooks || promiseBooks.length === 0) {
          this.getBookList([])
          return true
        }

        const checkResult = this.bookItems.every((book) =>
          promiseBooks.find((pBook) => pBook.bookId === book.bookId)
        )
        return !checkResult
      } catch (error) {
        // 紐づく生徒が0件の場合
        this.getBookList([])
        this.isLoading = false
        return true
      }
    },
    /**
     * 選択したグループが削除されている場合のモーダルOKボタン押下時
     */
    async onOkCloseShowChangedGroups() {
      // グループ再取得処理
      await this.onRefleshGroup()
      // モーダル表示フラグ初期化
      this.showChangedGroups = false
      this.stateGroupAccounts = false
      this.stateGroupIds = false
    },
    /**
    /**
     * グループ再取得処理
     */
    async onRefleshGroup() {
      let groupIdPromise = null
      let sukenAccountInfoPromise = null
      try {
        groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
          sukenServiceId.lms,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
        sukenAccountInfoPromise = await accountRepository.getSukenAccountInfo(
          sukenServiceId.lms,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
        if (sukenAccountInfoPromise.data.status === apiResponseCode.forbidden) {
          this.setSessionDisconnect(true)
          return
        }
      } catch (error) {
        if (error.status === apiResponseCode.forbidden) {
          this.setSessionDisconnect(true)
          return
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
      }

      // グループID
      this.setGroupIdsOfLoginUser(groupIdPromise.data.groupList)

      // 担当教科
      const curriculums = sukenAccountInfoPromise.data.chargedSubject
      // 教科IDを変換
      let isError = false
      const handleCurriculums = curriculums.map((code) => {
        let item = this.curriculumConversionTable.find(
          (item) => code == item.code
        )

        if (!item) {
          console.error(`Invalid curriculum code: ${code}`)
          isError = true
        }
        return Number(item.value)
      })

      if (isError) {
        // 変換できなかったものがあった場合は403エラーとする
        this.$router.push({
          name: "APIError",
          params: { status: apiResponseCode.forbidden },
        })
        return
      }
      this.setHandleCurriculumsOfLoginUser(handleCurriculums)

      // ブラウザリロード
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;
}
.question {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ques-wrap {
  height: max-content;
}
.tree-wrap {
  position: relative;
}
.tree {
  position: absolute;
  width: 90%;
  height: 100%;
}
.mt-3.mb-4 > .me-3 {
  min-width: 260px;
}
.mt-3.mb-4 > .me-3 + dev {
  max-width: 280px;
}
</style>
