import { render, staticRenderFns } from "./ModalUpdateInfo.vue?vue&type=template&id=123d39b8&scoped=true&"
import script from "./ModalUpdateInfo.vue?vue&type=script&lang=js&"
export * from "./ModalUpdateInfo.vue?vue&type=script&lang=js&"
import style0 from "./ModalUpdateInfo.vue?vue&type=style&index=0&id=123d39b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123d39b8",
  null
  
)

export default component.exports