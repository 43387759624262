<template>
  <div class="table-homework-progress-students">
    <table class="table-students">
      <thead>
        <tr>
          <th
            v-for="(header, index) in setHeaderItems"
            :key="`header-${index}`"
            class="col"
            :class="[
                {
                  first: header.isFirst,
                  last: header.isLast,
                },
              ]"
          >
            <div v-if="!showWhole && index === 0" class="d-flex align-items-center whole-toggle-button-area">
              <div
                class="d-flex justify-content-center align-items-center whole-toggle-button"
                @click="onClickShowWhole"
              >
                <div class="d-flex justify-content-center align-items-center whole-toggle-button-inner">
                  ＋
                </div>
              </div>
            </div>
            <div
              v-if="index === 0"
              class="blind-bulk-check"
            />

            <TableHeaderLabel
              :label-text="header.label"
              :sort-key="header.sortKey"
              :init-sort-order="header.initSortOrder"
              :is-selected="header.sortKey === selectedHeaderKey"
              class="text-center pre-line"
              @on-selected="onClickLabel"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in sortViewItems"
          :key="index"
          class="text-center"
        >
          <!-- 行番号 -->
          <!-- <th>{{ index + 1 }}</th> -->
          <td
            class="studentName text-center"
          >
            <div
                v-if="showBulkCheck"
                class="bulk-check-area"
              >
                <CheckBox
                  ref="bulkReturnCheckBox"
                  v-model="item.bulkCheck"
                  :initial-checked="false"
                  :disabled="!item.initBulkCheck"
                />
            </div>
            {{ showStudentName ? item.accountName : item.anonymousAccountName }}
          </td>
          <td class="status py-1">
            <BadgeStatus
              v-if="item.isInGroup"
              :label-text="item.statusDisplayName"
              :color="item.statusColor"
            />
          </td>
          <!-- スタンプ画像のサイズは固定 -->
          <td class="stamp">
            <img
              v-if="item.isInGroup && gradeStamps.length > 0 "
              :src="
                item.stamp === null
                  ? gradeStamps[gradeStamps.length - 1].stamp
                  : gradeStamps[item.stamp - 1].stamp
              "
              width="50"
            >
          </td>
          <!-- 評価 -->
          <td
            class="evalStatus m-0 p-0"
            :class="[
              item.isInGroup && item.evalManualSetFlg === 1
                ? 'bg-eval-manual'
                : '',
            ]"
          >
            <EvalStatus
              v-if="item.isInGroup && item.eval && item.eval > 0"
              :eval-code="item.eval"
            />
          </td>
          <!-- 回答率 -->
          <td class="response-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.responseRate ? 0 : item.responseRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 正答率（／回答） -->
          <td class="correct-answer-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.responseCorrectRate ? 0 : item.responseCorrectRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 正答率（／全体） -->
          <td class="response-correct-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.correctAnswerRate ? 0 : item.correctAnswerRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 確認ボタン -->
          <td class="check">
            <div v-if="item.isInGroup">
              <div
                v-if="
                  item.status === handedStatus.notProgress ||
                    item.status === handedStatus.progressing ||
                    item.status === handedStatus.waitHand
                "
                @click="checkStudentData(index, item.accountId)"
              >
                <ButtonProgressNotHanded />
              </div>
              <!-- 「提出済」以降のステータスでボタンを表示する -->
              <div
                v-else-if="item.status === handedStatus.handed"
                @click="checkStudentData(index, item.accountId)"
              >
                <ButtonProgressUnconfirm />
              </div>
              <div
                v-else
                @click="checkStudentData(index, item.accountId)"
              >
                <ButtonProgress />
              </div>
            </div>
          </td>
          <!-- 子問題を集計して正誤記号表示 -->
          <template v-for="(resultArray, arrayIndex) in item.results">
            <td
              v-for="(result, childIndex) in resultArray"
              :key="`${arrayIndex}-${childIndex}`"
              class="col"
              :class="[
                {
                  first: childIndex === 0,
                  last: childIndex === resultArray.length - 1,
                },
                !result || result.disableFlg
                  ? 'child-question bg-disable'
                  : 'child-question bg-layout-theme-light',
              ]"
            >
              <div v-if="result && !result.disableFlg">
                <div v-if="result.result === correctResult.notAnswer">
                  <IconDash width="25px" />
                </div>
                <div v-else-if="result.result === correctResult.correct">
                  <IconCircle
                    width="25px"
                    :color-style="correctColor"
                  />
                </div>
                <div v-else-if="result.result === correctResult.incorrect">
                  <IconClose
                    width="25px"
                    :color-style="incorrectColor"
                  />
                </div>
              </div>
              <div v-else />
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <ModalStudentHandedData
      v-if="showStudentDataModal"
      :student-data="sortViewItems"
      :stamps="gradeStamps"
      :student-num="studentNum"
      :submit-index-list="submitIndexList"
      :show-student-name="showStudentName"
      :teacher-comment="teacherComment"
      :haishin-start-date="haishinStartDate"
      :homework-details="homeworkDetails"
      :ques-image-list.sync="quesImageList"
      :config-page-teacher.sync="configPageTeacher"
      :cond-teacher.sync="condTeacher"
      v-on="{
        close: closeModalStudentHandedData,
        'on-click-return-of': onClickReturnOf,
        'on-click-send-back': onClickSendBack,
        'on-click-send-save': onClickSendSave,
        'on-click-student-data-transfer': checkStudentData,
        'on-change-show-student-name': ($event) => {
          $emit('on-change-show-student-name', $event)
        },
        'on-reload': onReload,
      }"
    />

    <ModalBulkReturn
      v-if="showBulkReturnModal"
      :student-data="sortViewItems"
      :stamps="gradeStamps"
      v-on="{
        close: closeModalBulkReturn,
        'on-click-return-of': onClickReturnOf,
        'on-click-send-back': onClickSendBack,
        'on-click-send-save': onClickSendSave,
        'on-reload': onReload,
      }"
    />
  </div>
</template>

<script>
import mixin from "../../mixins/mixin"
import { mapGetters } from "vuex"
import BadgeStatus from "@/components/atoms/BadgeStatus.vue"
import ButtonProgress from "@/components/atoms/buttons/ButtonProgress.vue"
import ButtonProgressUnconfirm from "@/components/atoms/buttons/ButtonProgressUnconfirm.vue"
import ButtonProgressNotHanded from "@/components/atoms/buttons/ButtonProgressNotHanded.vue"
import IconCircle from "@/components/atoms/icons/IconCircle.vue"
import IconClose from "@/components/atoms/icons/IconClose.vue"
import IconDash from "@/components/atoms/icons/IconDash.vue"
import ModalStudentHandedData from "@/components/organisms/modal/ModalStudentHandedData.vue"
import homeworkRepository from "@/repositories/homework"
import { correctResult, handedStatus } from "@/constant/homework"
import CheckBox from "@/components/atoms/CheckBox.vue";
import EvalStatus from "@/components/atoms/EvalStatus.vue"
import ModalBulkReturn from "@/components/organisms/modal/ModalBulkReturn.vue"
import TableHeaderLabel from "@/components/atoms/TableHeaderLabel.vue"
// ソート用のキー
// ここでしか使わないのでここに定義
const sortKeys = {
  accountId: "accountId",
  status: "statusSortKey",
  responseRate: "responseRate",
  responseCorrectRate: "responseCorrectRate",
  correctAnswerRate: "correctAnswerRate",
}

export default {
  name: "TableHomeworkProgressStudents",
  components: {
    BadgeStatus,
    ButtonProgress,
    ButtonProgressUnconfirm,
    ButtonProgressNotHanded,
    IconCircle,
    IconClose,
    IconDash,
    ModalStudentHandedData,
    CheckBox,
    EvalStatus,
    ModalBulkReturn,
    TableHeaderLabel,
  },

  mixins: [mixin],
  props: {
    headerItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    items: {
      type: Array,
      default: function () {
        return []
      },
    },
    viewItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    gradeStamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    progressParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      }
    },
    showStudentName: { type: Boolean },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
    quesImageList: {
      type: Array,
      default: function () {
        return {}
      },
    },
    configPageTeacher: {
      type: Object,
      default: function(){
        return {}
      },
    },
    condTeacher: {
      type: Object,
      default: function(){
        return {}
      },
    },
    showBulkCheck: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    showWhole: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
  },

  data: () => ({
    showStudentDataModal: false,
    showImageDetailModal: false,
    //生徒のindex番号
    studentNum: 0,
    headers: [],
    sortViewItems: [],
    selectedHeaderKey: sortKeys.accountId,
    sortOrder: 1,
    showed: false,
    // 正解の色
    correctColor: "#f00",
    // 不正解の色
    incorrectColor: "#0070c0",

    // template側で定数を使用するための定義
    correctResult: correctResult,
    handedStatus: handedStatus,

    // 一括返却
    // isBulkMode: false,
    // labelBulkMode: "一括返却",
    // labelBulkReturn: "返却",
    colorBulkReturn: "#ff7f27",
    // showBulkCheck: false,
    showBulkReturnModal: false,
    // 提出状況
    labelSubmissionStatus: "提出状況",
    selectedSubmissionStatus: 0,
    initialSubmissionStatus: "0",

    // ソート用一時データ
    sortTemp: [],
  }),

  computed: {
    ...mapGetters("nameConversions", ["gradeStampItems"]),

    setHeaderItems: function () {
      // 固定ヘッダ項目を初期設定
      (()=>{
        this.headers.length = 0
        this.headers.push(
          // 行番号
          // '',
          { label: "生徒名", sortKey: sortKeys.accountId, initSortOrder: 1, isFirst: true, isLast: true },
          { label: "提出状況", sortKey: sortKeys.status, initSortOrder: -1, isFirst: true, isLast: true },
          { label: "スタンプ", isFirst: true, isLast: true },
          { label: "評価", isFirst: true, isLast: true },
          { label: "回答率", sortKey: sortKeys.responseRate, initSortOrder: -1, isFirst: true, isLast: true },
          { label: "正答率\n(/回答)", sortKey: sortKeys.responseCorrectRate, initSortOrder: -1, isFirst: true, isLast: true },
          { label: "正答率\n(/全体)", sortKey: sortKeys.correctAnswerRate, initSortOrder: -1, isFirst: true, isLast: true },
          { label: "確認", isFirst: true, isLast: true },
        )
      }).apply()

      // ヘッダーに子問題タイトルを追加する
      this.headerItems.forEach((x) => {
        x.forEach((y, index) => {
          const isFirst = index === 0;
          const isLast = index === (x.length - 1);
          this.headers.push(
            { label: y, isFirst: isFirst, isLast: isLast })
        })
      })
      return this.headers
    },

    submitIndexList: function () {
      return this.items
        .map(function (item, index) {
          if (item.isInGroup) return index
        })
        .filter((item) => item === 0 || item)
    },
    // /**
    //  * 提出ステータスプルダウンリスト生成
    //  */
    // generateSubmissionStatusItems: function () {
    //   const array = [{
    //     label: '選択してください', value: '0'
    //   }].concat(this.items.map((v) => {
    //     // ステータスをプルダウン情報にコンバート
    //     return this.convertHandedStatusToSelectionValue(
    //       v.status,
    //       v.expired
    //     )
    //   }).reduce((a, v) => {
    //     // 重複を除去
    //     if (!a.some(e => e.value === v.value)) {
    //       a.push(v)
    //     }
    //     return a
    //   }, []))
      
    //   // ソート
    //   array.sort((a, b) => {
    //     return Number(a.value) > Number(b.value) ? 1 : -1
    //   })
      
    //   return array
    // },
    // bulkReturnDisabled: function () {
    //   return this.sortViewItems.filter(item => {
    //     return item.bulkCheck
    //   }).length === 0
    // },
  },

  watch: {
    // isBulkMode() {
    //   this.resetBulkCheck()
    // },
    showBulkCheck() {
      this.resetBulkCheck()
    },
    viewItems() {
      // 現在一括返却チェックしているアカウント一覧を取得し復元に利用する
      var bulkCheckedAccountIds = [];
      var initBulkCheckedAccountIds = [];
      if (this.sortViewItems) {
        bulkCheckedAccountIds = this.sortViewItems.filter(x => x.bulkCheck).map(x => x.accountId);
        initBulkCheckedAccountIds = this.sortViewItems.filter(x => x.initBulkCheck).map(x => x.accountId);
      }

      this.sortViewItems = this.viewItems.map((x, index) => {
        return {
          originIndex: index,
          accountId: x.accountId,
          accountName: x.accountName,
          anonymousAccountName: x.anonymousAccountName,
          bulkCheck: x.bulkCheck || bulkCheckedAccountIds.includes(x.accountId),
          comments: x.comments,
          correctAnswerRate: x.correctAnswerRate,
          eval: x.eval,
          evalManualSetFlg: x.evalManualSetFlg,
          expired: this.items[index].expired,
          handedDate: x.handedDate,
          handedTime: x.handedTime,
          homeworkKey: x.homeworkKey,
          initBulkCheck: x.initBulkCheck || initBulkCheckedAccountIds.includes(x.accountId),
          isInGroup: x.isInGroup,
          lastSubmitAddDate: x.lastSubmitAddDate,
          noteItems: x.noteItems,
          responseCorrectRate: x.responseCorrectRate,
          responseRate: x.responseRate,
          results: x.results,
          returnDate: x.returnDate,
          stamp: x.stamp,
          status: x.status,
          statusSortKey: this.getStatusSortKey(x.status, this.items[index].expired),
          statusColor: x.statusColor,
          statusDisplayName: x.statusDisplayName,
          studentComment: x.studentComment,
          teacherComment: x.teacherComment,
        }
      })

      // ソート処理
      this.convertedSortViewItems()
    }
  },

  methods: {
    // コメントを親画面に返す
    onClickReturnOf(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk = false) {
      this.items[this.studentNum].status = handedStatus.returned
      this.items[this.studentNum].statusColor =
        homeworkRepository.getProgressStatusColor(handedStatus.returned)
            this.$emit("on-click-return-of", studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk)
    },
    onClickSendBack(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk = false) {
      this.items[this.studentNum].status = handedStatus.sendBack
      this.items[this.studentNum].statusColor =
        homeworkRepository.getProgressStatusColor(handedStatus.sendBack)
            this.$emit("on-click-send-back", studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk)
    },
    onClickSendSave(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk = false) {
      this.items[this.studentNum].status = handedStatus.sendSave
      this.items[this.studentNum].statusColor =
        homeworkRepository.getProgressStatusColor(handedStatus.sendSave)
            this.$emit("on-click-send-save", studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, message, noteDrawList, callback, isBulk)
    },
    closeModalStudentHandedData() {
      // 提出データ画面を閉じるタイミングでソートしなおし
      this.sortTemp = []
      this.convertedSortViewItems()
      this.sortTemp = this.sortViewItems.map((v) => v.accountId)
      this.showStudentDataModal = !this.showStudentDataModal
    },
    checkStudentData(index, accountId) {
      this.showStudentDataModal = false
      this.showStudentDataModal = true
      this.studentNum = index
      this.$emit("on-checked-student-data", accountId)
    },
    onReload() {
      this.$emit("on-reload")
    },

    // /**
    //  * 宿題の一括返却切替
    //  */
    //  onClickBulkMode: function () {
    //   this.showBulkCheck = false
    //   this.isBulkMode = !this.isBulkMode
    // },

    /**
     * 宿題の一括返却
     */
    onClickBulkReturn: function () {
      // if (this.bulkReturnDisabled) {
      //   return
      // }
      this.showBulkReturnModal = false
      this.showBulkReturnModal = true
    },
    closeModalBulkReturn() {
      this.showBulkReturnModal = !this.showBulkReturnModal
      this.$emit('on-close-bulk-return')
    },

    // /**
    //  * 提出状況セレクトボックス選択
    //  */
    //  onSelectSubmissionStatus: async function (event) {
    //   this.selectedSubmissionStatus = event
    //   if (event != 0) {
    //     this.showBulkCheck = true
    //     await this.$nextTick()
    //     this.selectBulkReturnStatus(this.selectedSubmissionStatus)
    //   } else {
    //     this.showBulkCheck = false
    //   }
    // },
    getBulkReturnDisabled: function () {
      return this.sortViewItems.filter(item => {
        return item.bulkCheck
      }).length === 0
    },
    /**
     * 提出状況の一括選択
     */
    selectBulkReturnStatus: function (mode) {
      this.sortViewItems.forEach((item) => {
        let isBulkCheck = this.convertHandedStatusToSelectionValue(item.status, item.expired).value === mode
        item.bulkCheck = isBulkCheck
        item.initBulkCheck = isBulkCheck
      })
    },
    /**
     * 提出ステータスを一括返却提出状況プルダウン値にコンバート
     */
    convertHandedStatusToSelectionValue(status, expired) {
      switch (status) {
        case handedStatus.handed:
        case handedStatus.handChecked:
          return !expired ? {
            label: '提出済', value: '1'
          } : {
            label: '提出済（期限超過）', value: '2'
          }
        case handedStatus.sendBack:
        case handedStatus.sendBackCheck:
          return {
            label: 'やり直し', value: '3'
          }
        case handedStatus.notProgress:
        case handedStatus.progressing:
        case handedStatus.waitHand:
          return {
            label: '未提出', value: '4'
          }
        case handedStatus.returned:
        case handedStatus.returnChecked:
          return !expired ? {
            label: '返却済', value: '5'
          } : {
            label: '返却済（期限超過）', value: '6'
          }
      }
      return {
        label: '未提出', value: '4'
      }
    },
    /**
     * ステータスソートキーの取得
     */
    getStatusSortKey(status, expired) {
      return `${status}${ expired ? '1' : '0' }`
    },
    /**
     * 一括返却チェックボックスのリセット
     */
    resetBulkCheck() {
      if (!this.sortViewItems) {
        return
      }
      this.sortViewItems.forEach(v => {
        v.bulkCheck = false
        v.initBulkCheck = false
      })
    },

    /**
     * テーブルのラベル押下時処理
     */
    onClickLabel: function (key, order) {
      console.log(`onClickLabel`)
      this.selectedHeaderKey = key
      this.sortOrder = order
      this.sortTemp = []
      this.convertedSortViewItems()
      // ソート後の並び順を一時保存
      this.sortTemp = this.sortViewItems.map((v) => v.accountId)
    },
    /**
     * ソート処理
     */
    convertedSortViewItems: function () {
      let sortKey = this.selectedHeaderKey
      let sortOrder = this.sortOrder
      if (sortKey === "") {
        // 表示初期は生徒名の昇順
        sortKey = sortKeys.accountId
        sortOrder = 1
      }

      // ソートメソッド
      const sort = (a, b) => {
        let isUsingPositiveValue = undefined;
        const valA = a[sortKey];
        const valB = b[sortKey];
        if (valA !== valB) {
          isUsingPositiveValue = valA >= valB;
        }
        // 主キー相当の判定
        if (isUsingPositiveValue === undefined) {
          isUsingPositiveValue = a.accountId >= b.accountId
        }
        return isUsingPositiveValue ? sortOrder : -sortOrder
      }
      // テンポラリリストがある場合はテンポラリリストによるソート
      if (this.sortTemp && this.sortTemp.length > 0) {
        this.sortViewItems.sort((a, b) => {
          const ia = this.sortTemp.indexOf(a.accountId)
          const ib = this.sortTemp.indexOf(b.accountId)

          // 両方テンポラリリストにない（再読み込みで追加された）
          // ヘッダのソート順によるソート
          if (ia < 0 && ib < 0) {
            return sort(a, b)
          }

          // どちらかが再読み込みで追加されている場合は追加アカウントを末尾に
          if (ia < 0) return 1
          if (ib < 0) return -1

          // テンポラリのソート順によるソート
          return ia >= ib ? 1 : -1
        })
        return
      }
      this.sortViewItems.sort(sort)
    },
    /** 「＋」ボタン押下時  */
    onClickShowWhole() {
      this.$emit('on-show-whole')
    },
  },
}
</script>

<style lang="scss" scoped>

table {
  &.table-homework-progress-students, &.table-students {
    thead, tbody {
      tr {
        th, td {
          &.col {
            border-left: 1px solid #e1e1e1;
            border-right: 1px solid #e1e1e1;
          }
          &.col.first {
            border-left: 1px solid #b3b3b3;
          }
          &.col.last {
            border-right: 1px solid #b3b3b3;
          }
        }
      }
    }
  }
}

.table-homework-progress-students {
  position: relative;
}
table.table-students {
  .pre-line {
    white-space: pre-line;
  }

  thead {
    tr {
      height: 40px;
      color: var(--bs-layout-theme);

      th {
        background: var(--bs-table-header);
        border: 1px solid #b3b3b3;
        border-left: 0;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        min-width: 4rem;
        max-width: 4rem;
        width: 4rem;

        &::before {
          position: absolute;
          content: "";
          border-top: 1px solid #b3b3b3;
          border-bottom: 1px solid #b3b3b3;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          pointer-events: none;
        }

        &:nth-child(1) {
          border-left: 1px solid #b3b3b3;
          min-width: 10rem;
          max-width: 10rem;
          width: 10rem;

          .blind-bulk-check {
            background: #fff;
            width: 50px;
            height: calc(100% + 2px);
            position: absolute;
            top: -1px;
            right: calc(100% + 1px);
          }
        }
        &:nth-child(2) {
          min-width: 12rem;
          max-width: 12rem;
          width: 12rem;
        }
        &:nth-child(3) {
          min-width: 4rem;
          max-width: 4rem;
          width: 4rem;
        }
        &:nth-child(4) {
          min-width: 3rem;
          max-width: 3rem;
          width: 3rem;
        }
        &:nth-child(5) {
          min-width: 4.5rem;
          max-width: 4.5rem;
          width: 4.5rem;
        }
        &:nth-child(6) {
          cursor: pointer;
          min-width: 4.5rem;
          max-width: 4.5rem;
          width: 4.5rem;
        }
        &:nth-child(7) {
          min-width: 4.5rem;
          max-width: 4.5rem;
          width: 4.5rem;
        }
        &:nth-child(8) {
          min-width: 3rem;
          max-width: 3rem;
          width: 3rem;
        }
      }
    }
  }

  tbody {
    margin-left: -20px;
    tr {
      height: 50px;

      td {
        height: 3.08rem;
        padding: 0.15em 1em;
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        border-bottom: 1px solid #e1e1e1;

        &:nth-child(1) {
          border-left: 1px solid #b3b3b3;
        }

        .bulk-check-area {
          position: absolute;
          top: 15px;
          left: -20px;
        }

        &.bg-layout-theme-light {
          background: var(--bs-layout-theme-light);
        }

        &.bg-eval-manual {
          background: #ffffcc;
        }

        &.bg-disable {
          background: var(--bs-table-row-disable);
        }

        &.studentName {
          position: relative;
        }
        &.status {
          ::v-deep .badge {
            width: 100%;
            font-size: 0.9rem;
          }
        }

        &.evalStatus {
          font-size: 1.6em;
        }

        &.stamp {
          padding: 0;
        }
        &.response-rate {
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.correct-answer-rate {
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.response-correct-rate {
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.check {
          padding-left: 0;
          padding-right: 0;
        }
      }
      
      &:last-child {
        td {
          border-bottom: 1px solid #b3b3b3;
        }
      }
    }
  }
}


.whole-toggle-button-area {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  .whole-toggle-button {
    width: 30px;
    height: 30px;
    pointer-events: all;
    cursor: pointer;
    .whole-toggle-button-inner {
      width: 14px;
      height: 14px;
      font-size: 12px;
      border: 1px solid var(--bs-body-color);
      color: var(--bs-body-color);
      background-color: var(--bs-white);
    }
    }
}

</style>
